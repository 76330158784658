import { sendRequest } from '@/helpers/request';
import {
  API_DASHBOARD_STATISTIC,
  API_DASHBOARD_GRAPH,
  API_DASHBOARD_ACTUAL_DATA,
} from './api_pathes';

async function getDashboardStatistics(location) {
  try {
    const resp = await sendRequest(API_DASHBOARD_STATISTIC, { location }, 'GET');
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[DASHBOARD] Something went wrong with request to get dashboard statistics', error);
    return {};
  }
}

async function getDashboardBookingData({ startDate, endDate, location }) {
  try {
    const resp = await sendRequest(API_DASHBOARD_GRAPH, { startDate, endDate, location }, 'GET');
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[DASHBOARD] Something went wrong with request to get booking for dates data', error);
    return {};
  }
}

async function getDashboardActualData(location) {
  try {
    const resp = await sendRequest(API_DASHBOARD_ACTUAL_DATA, { location }, 'GET');
    return resp.ok ? resp.data : [];
  } catch (error) {
    console.error('[DASHBOARD] Something went wrong with request to get dashboard actual data', error);
    return {};
  }
}

export default {
  getDashboardStatistics,
  getDashboardBookingData,
  getDashboardActualData,
};
