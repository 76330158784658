export default {
  en: {
    investWebsite: 'Investment website',
    guestWebsite: 'Website for guests',
    privacy: 'Privacy policy',
    madeBy: 'Made by',
  },
  ru: {
    investWebsite: 'Cайт инвестиций',
    guestWebsite: 'Сайт для гостей комплекса',
    privacy: 'Политика конфиденциальности',
    madeBy: 'Сделано в',
  },
};
