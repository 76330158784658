export default {
  en: {
    oneUnit: 'unit',
    fewUnits: 'units',
    moreUnits: 'units',
    funded: 'Funded by dewis',
    locationsInfo: {
      volochaevka: {
        name: 'Volochaevka',
        title: 'VIEW GA Volochaevka Investment Project (VIEW GA Forest SPA Hotel Complex)',
        about: 'A four-star hotel complex surrounded by a relict forest, just 250 meters from the lake.',
        place: 'Volochaevka village, 90 km from St.\u2011Petersburg',
        infrastructure: [
          'Year-round outdoor pool',
          '2 year-round Jacuzzi pools',
          'Hammam',
          'Summer kitchen',
          'Retreat area with baths',
          'Activity area (ice rink in winter, a court for volleyball and other games in summer)',
          'Eco-shop',
          'Forest cuisine restaurant',
        ],
      },
      simagino: {
        name: 'Simagino',
        title: 'Simagino VIEW GA Investment Project (VIEW GA Water Lodge Hotel Complex)',
        about: 'A unique hotel complex with houseboats on the shore of the Bolshoye Simaginskoe Lake.',
        place: 'Ilyichevo village, 50 km from St.\u2011Petersburg',
        area: {
          forestArea: 'Forest',
          lakeArea: 'Lake',
        },
        infrastructure: [
          'Banya with a steam room, a relaxation area and a furako bath in the forest part',
          'Individual pontoon banyas at the lake',
          'Pontoon patios for relaxation and special events',
          'Restaurant with panoramic lake view and summer terrace',
          'A bay with an inflatable water park (in summer) / ice rink (in winter)',
          'Water transport rental (catamarans, boats, SUP boards)',
          'SPA complex with thermae baths, hammam, furako baths and a panoramic pool',
          'Equipped recreation area with art objects',
        ],
      },
      leskolovo: {
        name: 'Leskolovo',
        title: 'Leskolovo VIEW GA Investment Project (VIEW GA Anima Hotel Complex)',
        about: 'A unique hotel with mirrored facades, surrounded by a coniferous forest on the lake shore.',
        place: 'Leskolovo village, 50 km from St.\u2011Petersburg',
        infrastructure: [
          'Restaurant club with a concert venue, karaoke and a VIP patio',
          'Grill patio for recreation and special events',
          'Separate SPA modules with steam room, relaxation area and a furako bath',
          'Banya complex with SPA programs and a panoramic pool',
          'Café with a cinema on the water',
          'Equipped area for beach recreation and sports',
        ],
      },
      unitTypes: {
        standart: 'Standard',
        halfLux: 'Junior suite',
        lux: 'Suite',
      },
      peripheryTypes: {
        banya: 'Banya',
        banyaComplex: 'Banya complex',
        patio: 'Patio',
        pantoonBanya: 'Pantoon banya',
        grillPatio: 'Grill patio',
        spaModule: 'SPA module',
      },
      common: {
        units: 'Units',
        location: 'Location',
        addedValue: 'Infrastructure that creates added value',
        accom: 'Accommodation',
        periphery: 'Outbuildings',
      },
    },
  },
  ru: {
    oneUnit: 'юнит',
    fewUnits: 'юнита',
    moreUnits: 'юнитов',
    funded: 'Финансирует dewis',
    locationsInfo: {
      volochaevka: {
        name: 'Волочаевка',
        title: 'Инвестиционный проект VIEW GA «Волочаевка» (Гостиничный комплекс VIEW GA Forest SPA)',
        about: `Четырехзвездочный гостиничный комплекс,
        расположенный в окружении реликтового леса в 250 метрах от озера.`,
        place: 'п. Волочаевка, 90 км от Санкт\u2011Петербурга',
        infrastructure: [
          'Круглогодичный открытый бассейн',
          '2 круглогодичных бассейна-джакузи',
          'Хаммам',
          'Летняя кухня',
          'Ретрит-зона с купелями',
          `Многофункциональная площадка активностей
          (каток зимой, площадка для волейбола и других игр летом)`,
          'Эко-лавка',
          'Ресторан лесной кухни',
        ],
      },
      simagino: {
        name: 'Симагино',
        title: 'Инвестиционный проект VIEW GA «Симагино» (Гостиничный комплекс VIEW GA Water Lodge)\n',
        about: `Уникальный гостиничный комплекс с плавучими домами на берегу
        Большого Симагинского озера.`,
        place: 'п. Ильичево, 50 км от Санкт\u2011Петербурга',
        area: {
          forestArea: 'Лесная часть',
          lakeArea: 'Озерная часть',
        },
        infrastructure: [
          'Банный комплекс с парной, комнатой отдыха и купелью-фурако в лесной части',
          'Индивидуальные бани на понтонах в озерной части',
          'Беседки-патио на понтонах для отдыха и торжественных событий',
          'Ресторан с панорамным видом на озеро и летней террасой',
          'Бухта с надувным аквапарком (летом) / катком (зимой)',
          'Прокат водного транспорта (катамараны, лодки, sup-доски)',
          'Банный СПА-комплекс с термами, хаммамом, купелями и видовым бассейном',
          'Оборудованная зона отдыха с арт-объектами',
        ],
      },
      leskolovo: {
        name: 'Лесколово',
        title: 'Инвестиционный проект VIEW GA «Лесколово» (Гостиничный комплекс VIEW GA Anima)',
        about: `Уникальный отель с зеркальной архитектурой фасадов в
        окружении хвойного леса на берегу озера.`,
        place: 'д. Лесколово, 50 км от Санкт\u2011Петербурга',
        infrastructure: [
          'Ресторан-клуб с концертной площадкой, караоке и VIP-патио',
          'Гриль-патио для отдыха и торжественных событий',
          'Индивидуальные СПА-модули с парной, комнатой отдыха и купелью',
          'Банный комплекс со СПА-программами и с видовым бассейном',
          'Кафе с кинотеатром на воде',
          'Оборудованная зона для пляжного отдыха и спорта',
        ],
      },
      unitTypes: {
        standart: 'Стандарт',
        halfLux: 'Полулюкс',
        lux: 'Люкс',
      },
      peripheryTypes: {
        banya: 'Баня',
        banyaComplex: 'Банный комплекс',
        patio: 'Патио',
        pantoonBanya: 'Баня на понтонах',
        grillPatio: 'Гриль-патио',
        spaModule: 'СПА-модуль',
      },
      common: {
        units: 'Юниты',
        location: 'Месторасположение',
        addedValue: 'Инфраструктура, создающая добавленную ценность',
        accom: 'Проживание',
        periphery: 'Периферия',
      },
    },
  },
};
