import { TIMEZONE_DIFF, ONE_WEEK, WEEKEND_DAYS } from '@/constants/dates';

export const isToday = (someDate) => {
  const today = new Date();

  return someDate.getDate() === today.getDate()
    && someDate.getMonth() === today.getMonth()
    && someDate.getFullYear() === today.getFullYear();
};

export const prepareNumericDate = (someDate, exceptions = {}, replaceVal = ' / ') => {
  const options = {
    day: exceptions.day ? undefined : 'numeric',
    month: exceptions.month ? undefined : 'numeric',
    year: exceptions.year ? undefined : 'numeric',
  };

  return new Date(someDate).toLocaleString(
    'ru-RU',
    options,
  ).replace(/\./g, replaceVal);
};

export const prepareToYYYYMMDD = (someDate) => {
  const dateObj = new Date(someDate);
  const year = dateObj.toLocaleDateString('ru-RU', { year: 'numeric' });
  const month = dateObj.toLocaleDateString('ru-RU', { month: '2-digit' });
  const day = dateObj.toLocaleDateString('ru-RU', { day: '2-digit' });
  return `${year}-${month}-${day}`;
};

export const prepareShortMonthDate = (someDate, exceptions = {}) => {
  if (!someDate) {
    return '';
  }

  const dateObj = new Date(someDate);
  return `
    ${exceptions.day ? '' : dateObj.toLocaleDateString('ru-RU', { day: '2-digit' })}
    ${exceptions.notShort ? dateObj.toLocaleDateString('ru-RU', { month: 'long' })
    : dateObj.toLocaleDateString('ru-RU', { month: 'long' }).slice(0, 3)}
    ${exceptions.year ? '' : dateObj.toLocaleDateString('ru-RU', { year: 'numeric' })}
  `;
};

export const isSameDay = (firstDate, secondDate) => firstDate.getFullYear()
  === secondDate.getFullYear()
  && firstDate.getMonth() === secondDate.getMonth()
  && firstDate.getDate() === secondDate.getDate();

export const isDayInDatesRange = (day, range) => (isSameDay(new Date(day), new Date(range[0]))
    || day > range[0])
  && (isSameDay(new Date(day), new Date(range[1])) || day < range[1]);

export const getListOfMonths = (startTime) => {
  const list = [];
  const startDate = new Date(startTime);
  const todayDate = new Date();
  const currentDate = startDate;

  while (currentDate.getMonth() <= todayDate.getMonth()
  && currentDate.getFullYear() <= todayDate.getFullYear()) {
    list.push({
      id: currentDate.getTime(),
      label: `${currentDate.toLocaleDateString('ru-RU', { month: 'long' })} ${currentDate.getFullYear()}`,
    });

    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return list;
};

export const getTimeline = (start, end) => {
  const dateStart = new Date(start);
  const dateEnd = new Date(end);
  const months = [{
    time: dateStart.getTime(),
    year: dateStart.getFullYear(),
    monthNumber: dateStart.getMonth(),
    monthName: dateStart.toLocaleString('ru-RU', { month: 'long' }),
    days: [],
  }];
  const flatDays = [];

  let currentMonthIndex = 0;
  const dayInMilliseconds = (1000 * 60 * 60 * 24);

  for (let i = dateStart.getTime(); i < dateEnd.getTime(); i += dayInMilliseconds) {
    const currTime = new Date(i);
    if (currTime.getMonth() !== months[currentMonthIndex].monthNumber) {
      months.push({
        time: currTime.getTime(),
        year: currTime.getFullYear(),
        monthNumber: currTime.getMonth(),
        monthName: currTime.toLocaleString('ru-RU', { month: 'long' }),
        days: [],
      });

      currentMonthIndex += 1;
    }

    const dayName = currTime.toLocaleDateString('ru-RU', { weekday: 'short' });
    const day = {
      time: currTime.getTime(),
      date: currTime.getDate(),
      name: dayName,
      isToday: isToday(currTime),
      isWeekend: WEEKEND_DAYS
        .find((weekendDay) => weekendDay.toLowerCase() === dayName.toLowerCase()),
    };
    months[currentMonthIndex].days.push(day);
    flatDays.push(day);
  }

  return {
    months,
    flatDays,
  };
};

export const updateBookingUnitsData = (bookingData, timeline) => !bookingData
  ? []
  : bookingData.map((group) => {
    const unitsAmount = group.units.length;
    const unitBookings = group.units
      .reduce((flatBookings, unit) => {
        const bookingsOfCurrentUnit = unit.bookings.map((booking) => booking.dates);
        const repairsOfCurrentUnit = unit.repairs
          .map((repair) => [new Date(repair.startDateTime), new Date(repair.endDateTime)]);
        return [...flatBookings, ...bookingsOfCurrentUnit, ...repairsOfCurrentUnit];
      }, []);
    const updatedGroup = group;
    updatedGroup.availableUnitsPerDay = timeline.map((day) => {
      let availableUnitsInDayAmount = unitsAmount;
      unitBookings.forEach((booking) => {
        if (isDayInDatesRange(day.time, booking)) {
          availableUnitsInDayAmount -= 1;
        }
      });

      return {
        date: day.time,
        isToday: isToday(new Date(day.time)),
        isWeekend: WEEKEND_DAYS
          .includes(new Date(day.time)
            .toLocaleDateString('ru-RU', { weekday: 'short' }).toLowerCase()),
        numberOfUnits: availableUnitsInDayAmount,
      };
    });

    updatedGroup.units.forEach((unit, index) => {
      updatedGroup.units[index].days = timeline.map((day) => {
        const dayOfUnit = {
          date: day.time,
          isEmpty: true,
        };
        const bookedDays = unit.bookings
          .filter((booking) => isDayInDatesRange(day.time, booking.dates));
        const repairedDays = unit.repairs
          .filter((repair) => isDayInDatesRange(
            day.time,
            [new Date(repair.startDateTime).getTime(), new Date(repair.endDateTime).getTime()],
          ));
        if (bookedDays.length) {
          bookedDays.forEach((booking) => {
            const info = { ...booking };
            info.endDateTime = booking.dates[1];
            info.datesRangeString = `
              ${prepareShortMonthDate(booking.dates[0])} — ${prepareShortMonthDate(booking.dates[1])}
            `;

            if (dayOfUnit.info && dayOfUnit.info.length) {
              dayOfUnit.info = dayOfUnit.info[0].endDateTime > info.endDateTime
                ? [info, ...dayOfUnit.info]
                : [...dayOfUnit.info, info];
            } else {
              dayOfUnit.info = [info];
            }

            if (dayOfUnit.isEmpty) {
              dayOfUnit.isStart = isSameDay(new Date(booking.dates[0]), new Date(day.time));
              dayOfUnit.isEnd = isSameDay(new Date(booking.dates[1]), new Date(day.time));
            } else {
              dayOfUnit.isStart = false;
              dayOfUnit.isEnd = false;
              dayOfUnit.isBorderline = true;
            }

            const dayStatus = booking.status.toLowerCase();
            dayOfUnit.status = dayStatus === 'checkedin' ? 'new' : dayStatus;
            dayOfUnit.isEmpty = false;
          });
        }
        if (repairedDays.length) {
          repairedDays.forEach((repair) => {
            const info = { ...repair };
            info.status = 'repair';
            info.source = {
              type: 'repair',
            };
            info.endDateTime = new Date(repair.endDateTime);
            info.datesRangeString = `
              ${prepareShortMonthDate(repair.startDateTime)} — ${prepareShortMonthDate(repair.endDateTime)}
            `;

            if (dayOfUnit.info && dayOfUnit.info.length) {
              dayOfUnit.info = dayOfUnit.info[0].endDateTime > info.endDateTime
                ? [info, ...dayOfUnit.info]
                : [...dayOfUnit.info, info];
            } else {
              dayOfUnit.info = [info];
            }

            if (dayOfUnit.isEmpty) {
              dayOfUnit.isStart = isSameDay(new Date(repair.startDateTime), new Date(day.time));
              dayOfUnit.isEnd = isSameDay(new Date(repair.endDateTime), new Date(day.time));
            } else {
              dayOfUnit.isStart = false;
              dayOfUnit.isEnd = false;
              dayOfUnit.isBorderline = true;
            }

            dayOfUnit.status = bookedDays.length ? 'both' : 'repair';
            dayOfUnit.isEmpty = false;
          });
        }

        dayOfUnit.isToday = isToday(new Date(day.time));
        dayOfUnit.isWeekend = WEEKEND_DAYS
          .includes(new Date(day.time)
            .toLocaleDateString('ru-RU', { weekday: 'short' }).toLowerCase());

        return dayOfUnit;
      });
    });

    return updatedGroup;
  });

export const updateBookingPeripheryData = (bookingData, timeline) => bookingData.map((group) => {
  const peripheryAmount = group.units.length;
  const peripheryBookings = group.units
    .reduce((flatBookings, unit) => {
      const bookingsOfCurrentPeriphery = unit.bookings.map((booking) => booking.dates);
      return [...flatBookings, ...bookingsOfCurrentPeriphery];
    }, []);
  const updatedGroup = group;
  updatedGroup.availablePeripheryPerHour = timeline.reduce((acc, day) => {
    const hours = [];
    const bookedHours = {};

    peripheryBookings.forEach((booking) => {
      if (isDayInDatesRange(day.time, booking)) {
        const start = new Date(booking[0]);
        const end = new Date(booking[1]);

        for (let i = 0; i <= 23; i += 1) {
          if (start.getHours() <= i && end.getHours() >= i) {
            bookedHours[i] = bookedHours[i]
              ? bookedHours[i] - 1
              : peripheryAmount - 1;
          }
        }
      }
    });

    for (let i = 0; i <= 23; i += 1) {
      hours.push({
        date: day.time,
        hour: i,
        isCurrentHour: new Date().getHours() === i && isToday(new Date(day.time)),
        numberOfUnits: bookedHours[i] || bookedHours[i] === 0 ? bookedHours[i] : peripheryAmount,
      });
    }

    return [...acc, ...hours];
  }, []);

  updatedGroup.units.forEach((unit, index) => {
    updatedGroup.units[index].hours = timeline.reduce((acc, day) => {
      const hours = [];

      for (let i = 0; i <= 23; i += 1) {
        const hourOfUnit = {
          date: day.time,
          hour: i,
          isEmpty: true,
        };

        unit.bookings.forEach((booking) => {
          const start = new Date(booking.dates[0]);
          const end = new Date(booking.dates[1]);
          const isDifferentDays = start.getDate() !== end.getDate();
          const isCurrentDayIsStartDay = start.getDate() === day.date;
          const isCurrentDayIsEndDay = end.getDate() === day.date;

          let isCurrentHourIsNotEmpty = false;

          if (isCurrentDayIsStartDay) {
            isCurrentHourIsNotEmpty = start.getHours() <= i;
          }
          if (isCurrentDayIsEndDay) {
            isCurrentHourIsNotEmpty = end.getHours() >= i;
          }
          if (!isDifferentDays) {
            isCurrentHourIsNotEmpty = start.getHours() <= i && end.getHours() >= i;
          }

          if ((isSameDay(start, new Date(day.time))
            || isSameDay(end, new Date(day.time)))
            && isCurrentHourIsNotEmpty
          ) {
            const datesRangeString = `${start
              .toLocaleString(
                'ru-RU',
                {
                  hour: '2-digit',
                  minute: '2-digit',
                },
              )
            } — ${end
              .toLocaleString(
                'ru-RU',
                {
                  hour: '2-digit',
                  minute: '2-digit',
                },
              )}`;
            // проверяем есть ли уже инфа о бронирование в этот час, тк возможны ситуации когда
            // время бронирования с 11 до 14 и с 14 до 18, например -- то есть в один час два
            // блока информации
            const info = { ...booking };
            info.datesRangeString = datesRangeString;

            hourOfUnit.info = hourOfUnit.info
              ? [...hourOfUnit.info, info]
              : [info];

            if (hourOfUnit.isEmpty) {
              hourOfUnit.isStart = start.getHours() === i;
              hourOfUnit.isEnd = end.getHours() === i;
            } else {
              hourOfUnit.isStart = false;
              hourOfUnit.isEnd = false;
              hourOfUnit.isBorderline = true;
            }

            const dayStatus = booking.status.toLowerCase();
            hourOfUnit.status = dayStatus === 'checkedin' ? 'new' : dayStatus;
            hourOfUnit.isEmpty = false;
          }
        });

        hourOfUnit.isCurrentHour = new Date().getHours() === i && isToday(new Date(day.time));
        hourOfUnit.isCurrentHour = new Date().getHours() === i && isToday(new Date(day.time));

        hours.push(hourOfUnit);
      }

      return [...acc, ...hours];
    }, []);
  });

  return updatedGroup;
});

export const pastDatesOptions = {
  weeks: () => {
    const todayDate = new Date();
    let monthAgo = new Date();
    let twoMonthAgo = new Date();
    monthAgo = monthAgo.setMonth(monthAgo.getMonth() - 1);
    twoMonthAgo = twoMonthAgo.setMonth(twoMonthAgo.getMonth() - 2);

    return [
      {
        id: todayDate.getTime() - ONE_WEEK,
        label: 'weekAgo',
      },
      {
        id: todayDate.getTime() - ONE_WEEK * 2,
        label: 'twoWeekAgo',
      },
      {
        id: monthAgo,
        label: 'monthAgo',
      },
      {
        id: twoMonthAgo,
        label: 'twoMonthAgo',
      },
    ];
  },
  months: () => {
    const todayDate = new Date();
    let monthAgo = new Date();
    let threeMonthAgo = new Date();
    let yearAgo = new Date();
    monthAgo = monthAgo.setMonth(monthAgo.getMonth() - 1);
    threeMonthAgo = threeMonthAgo.setMonth(threeMonthAgo.getMonth() - 3);
    yearAgo = yearAgo.setMonth(yearAgo.getMonth() - 12);

    return [
      {
        id: todayDate.getTime() - ONE_WEEK * 2,
        label: 'Последние две недели',
      },
      {
        id: monthAgo,
        label: 'Последний месяц',
      },
      {
        id: threeMonthAgo,
        label: 'Последние три месяца',
      },
      {
        id: yearAgo,
        label: 'Последний год',
      },
    ];
  },
};

export const futureDatesOptions = {
  months: () => {
    // const todayDate = new Date();
    let monthAfter = new Date();
    let twoMonthAfter = new Date();
    monthAfter = monthAfter.setMonth(monthAfter.getMonth() + 1);
    twoMonthAfter = twoMonthAfter.setMonth(twoMonthAfter.getMonth() + 2);

    return [
      {
        id: monthAfter,
        label: 'inMonth',
      },
      {
        id: twoMonthAfter,
        label: 'inTwoMonths',
      },
    ];
  },
};

export const checkMonthsOrder = (item) => {
  const checkedMonth = item + 1;
  if (checkedMonth.toString().length < 2) {
    return `0${checkedMonth}`;
  }
  return `${checkedMonth}`;
};

export const prepareRequestDate = (date) => {
  const year = (new Date(date + TIMEZONE_DIFF).toISOString()).slice(0, 4);
  const month = (new Date(date + TIMEZONE_DIFF).toISOString()).slice(5, 7);

  return Number(year + month);
};
// (new Date(date + TIMEZONE_DIFF).toISOString()).slice(0, 10);
