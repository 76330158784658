<template>
  <dashboard-layout
    class="income-page"
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #outer-content>
      <ui-tabs
        :list="userLocations"
        :preselected-tab-id="currentUserLocation ? currentUserLocation.id : null"
        @tab-change="locationChangeHandler"
      />
    </template>
    <template #side-content>
      <main-side-menu
        :item-list="menuItems"
        active-item-id="income"
      />
      <user-actives
        :units-list="userUnits"
      />
    </template>
    <template #main-content>
      <title-main
        class="income-page__title"
        :text="t('incomePage.statistics')"
        icon
      >
        <template #icon>
          <img src="../assets/booking.svg" alt="booking-icon">
        </template>
      </title-main>
      <div class="income-page__info">
        Показан доход после удержания НДФЛ
      </div>
      <div class="income-page__summary-table">
        <summary-table
          :class="{
            'loading-stub': isLoadingStatistics,
          }"
        >
          <div
            v-if="isInvestor"
            class="summary-table__row"
          >
            <div class="summary-table__block">
              <summary-info
                :header="t('incomePage.averageMonthlyIncome')"
                :firstInfo="averageMonthlyIncome"
              />
            </div>
            <div class="summary-table__block">
              <summary-info
                :header="t('incomePage.averagePercentForYear')"
                :firstInfo="averagePercentForYear"
                :popupData="{
                  header: t('incomePage.popups.roiPerYear.header'),
                  text: t('incomePage.popups.roiPerYear.text'),
                }"
              />
            </div>
            <div class="summary-table__block">
              <summary-info
                :header="t('incomePage.averagePercentForMonth')"
                :firstInfo="averagePercentForMonth"
                :popupData="{
                  header: t('incomePage.popups.roiPerPeriod.header'),
                  text: t('incomePage.popups.roiPerPeriod.text'),
                }"
              />
            </div>
          </div>
          <div class="summary-table__row">
            <div class="summary-table__block">
              <summary-info
                :header="isInvestor ? t('incomePage.youInvested') : t('incomePage.youProvided')"
                :firstInfo="invested"
              />
            </div>
            <div class="summary-table__block">
              <summary-info
                :header="t('incomePage.totalEarned')"
                :firstInfo="totalIncome"
              />
            </div>
            <!-- PERSPACE402 -->
            <div
              v-if="!isInvestor"
              class="summary-table__block"
            >
              <summary-info
                :header="isInvestor ? t('incomePage.assetProfitability') : t('incomePage.loanTerm')"
                :firstInfo="isInvestor ? payback : loanTermYearMonth"
                :popup-data="payback !== '–' && isInvestor ? {
                  header: t('incomePage.popups.payback.header'),
                  text: t('incomePage.popups.payback.text'),
                } : {}"
              />
            </div>
          </div>
        </summary-table>
      </div>
      <div class="income-page__chart-header">
        <title-main
          class="income-page__title"
          :text="t('incomePage.yourIncome')"
          icon
        >
          <template #icon>
            <img src="../assets/income.svg" alt="income-icon">
          </template>
        </title-main>
        <div
          class="income-page__chart-header-controls"
          v-if="incomeMonthlyDatesFilter && incomeMonthlyDatesFilter.length"
        >
          <div class="income-page__chart-header-controls-wrapper">
            <filter-dropdown
              class="income-page__filter-dropdown--desktop"
              v-if="incomeChartAllData && incomeChartAllData.length > 4 && isInvestor"
              selectView
              :filter-name="t('incomePage.selectedUnit')"
              :filter-values="incomeChartFilterList"
              :pre-selected-value="incomeChartFilterList[0]"
              @filter-select="incomeChartFilterHandler"
            />
            <secondary-button
              v-if="monthlyData && monthlyData.length"
              :label="t('incomePage.saveAsXLS')"
              @click="downloadHandler"
            >
              <template #iconBefore>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.46968 0.221406C5.76255 -0.0738527 6.23745 -0.0738527 6.53032
                  0.221406L9.53032 3.24567C9.8232 3.54094 9.8232 4.01965 9.53032 4.31491C9.23745
                  4.61017 8.76255 4.61017 8.46968 4.31491L6.75
                  2.58133V8.35035C6.75 8.76793 6.41423
                  9.10642 6 9.10642C5.58577 9.10642 5.25 8.76793 5.25 8.35035V2.58133L3.53033
                  4.31491C3.23744 4.61017 2.76256 4.61017 2.46967 4.31491C2.17678 4.01965 2.17678
                  3.54094 2.46967 3.24567L5.46968 0.221406ZM0.75
                  8.21967C1.16421 8.21967 1.5 8.55816
                  1.5 8.97574V10.4879H10.5V8.97574C10.5 8.55816
                  10.8358 8.21967 11.25 8.21967C11.6642
                  8.21967 12 8.55816 12 8.97574V10.4879C12
                  11.323 11.3285 12 10.5 12H1.5C0.671572 12
                  0 11.323 0 10.4879V8.97574C0 8.55816 0.33579 8.21967 0.75 8.21967Z"/>
                </svg>
              </template>
            </secondary-button>
            <filter-date
              calendar-only
              is-range
              is-month-picker
              :preselected-range="incomeMonthlyDatesFilter"
              @filter-date-select="incomeDatesFilterHandler"
              calendarDatePopupPosition="right"
              calendarIcon
            />
            <filter-dropdown
              class="income-page__filter-dropdown--mobile"
              v-if="incomeChartAllData && incomeChartAllData.length && isInvestor"
              selectView
              :filter-name="t('incomePage.selectedUnit')"
              :filter-values="incomeChartFilterList"
              :pre-selected-value="incomeChartFilterList[0]"
              @filter-select="incomeChartFilterHandler"
            />
          </div>
        </div>
      </div>
      <div
        v-if="(incomeChartCurrentData && incomeChartCurrentData.length)
        || (incomeChartAllData && incomeChartAllData.length)"
        class="income-page__chart-wrapper"
        :class="{
          'income-page__chart-wrapper--is-loading':isLoading,
        }"
      >
        <div
          v-if="isLoading"
          class="income-page__loader"
        >
          <span class="income-page__loader-icon"/>
        </div>
        <chart-legend
          v-if="isInvestor"
          :items="chartLegend"
        />
        <div
          v-if="incomeChartDates"
          class="income-page__chart income-page__chart--desktop"
        >
          <info-chart
            :data="
              incomeChartAllData.length > 4 && isInvestor
                ? incomeChartCurrentData
                : incomeChartAllData
              "
            :isMultiplyColumns="isInvestor"
            :customTooltip="tooltipData"
            disableDistibutedColors
            :categories="incomeChartDates"
            rotateLabels="-90"
            :yaxisOptions="infoChartYaxisOptions"
          />
        </div>
        <div
          v-if="incomeChartDates && isInvestor"
          class="income-page__chart income-page__chart--mobile"
        >
          <info-chart
            :data="incomeChartCurrentData"
            :isMultiplyColumns="isInvestor"
            :customTooltip="tooltipData"
            disableDistibutedColors
            :categories="incomeChartDates"
            rotateLabels="-90"
            :yaxisOptions="infoChartYaxisOptions"
          />
        </div>
      </div>
      <stub-block-no-data
        v-else
        class="income-page__stub"
        :title="t('incomePage.noIncomeDataTitle')"
        :sub-title="t('incomePage.noIncomeDataSubTitle')"
      >
        <template #icon>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
               viewBox="0 0 24 24" fill="none">
            <path d="M2 20V3H0V21C0 21.2652 0.105357 21.5196 0.292893
                    21.7071C0.48043 21.8946 0.734784 22 1 22H24V20H2Z" fill="#89909E"/>
            <path d="M9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054
                    8.26522 11
                8 11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4
                12V19H9V12Z" fill="#89909E"/>
            <path d="M16 8C16 7.73478 15.8946 7.48043 15.7071 7.29289C15.5196 7.10536
                    15.2652
                7 15 7H12C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11
                8V19H16V8Z" fill="#89909E"/>
            <path d="M23 4C23 3.73478 22.8946 3.48043 22.7071 3.29289C22.5196 3.10536
                    22.2652
                3 22 3H19C18.7348 3 18.4804 3.10536 18.2929 3.29289C18.1054 3.48043 18 3.73478 18
                4V19H23V4Z" fill="#89909E"/>
          </svg>
        </template>
      </stub-block-no-data>
      <div
        v-if="isInvestor"
        class="income-page__units"
      >
        <div class="income-page__units-header">
          <title-secondary
            :text="t('incomePage.incomeByAssets')"
          />
          <filter-date
            calendar-only
            is-month-picker
            no-day-button
            @filter-date-select="monthOfIncomeByUnitsSelected"
            calendarDatePopupPosition="right"
            arrowIcon
          />
        </div>
        <stub-block-no-data
          v-if="!incomeByUnits || !incomeByUnits.length"
          class="income-page__stub"
          :title="t('incomePage.noIncomeByAssetsTitle')"
          :sub-title="t('incomePage.noIncomeByAssetsSubTitle')"
        >
          <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M2 20V3H0V21C0 21.2652 0.105357 21.5196 0.292893 21.7071C0.48043 21.8946
                0.734784 22 1 22H24V20H2Z" fill="#89909E"/>
              <path d="M9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11
                8 11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4
                12V19H9V12Z" fill="#89909E"/>
              <path d="M16 8C16 7.73478 15.8946 7.48043 15.7071 7.29289C15.5196 7.10536 15.2652
                7 15 7H12C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11
                8V19H16V8Z" fill="#89909E"/>
              <path d="M23 4C23 3.73478 22.8946 3.48043 22.7071 3.29289C22.5196 3.10536 22.2652
                3 22 3H19C18.7348 3 18.4804 3.10536 18.2929 3.29289C18.1054 3.48043 18 3.73478 18
                4V19H23V4Z" fill="#89909E"/>
            </svg>
          </template>
        </stub-block-no-data>
        <summary-table
          v-else
          :class="{
          'loading-stub': isLoadingStatistics,
        }"
        >
          <div class="summary-table__row">
            <div
              v-for="unit in incomeByUnits"
              :key="`${unit.monthlyIncome}${unit.activeId}`"
              class="summary-table__block"
            >
              <unit-income
                :header-text="unit.unitName"
                :price-text="unit.monthlyIncome"
                :metric-number="unit.previousMonthPercent"
              />
            </div>
          </div>
        </summary-table>
      </div>
      <div
        class="income-page__area-chart"
        v-if="capitalizationChartData && capitalizationChartData.series.length  && false"
      >
        <div class="income-page__area-chart-header">
          <title-main
            :text="t('incomePage.assetCapitalization')"
            icon
          >
            <template #icon>
              <img src="../assets/income-chart-icon.svg" alt="income-chart-icon">
            </template>
          </title-main>
          <filter-dropdown
            :filter-values="areaChartFilterList"
            :pre-selected-value="areaChartFilterList[0]"
            @filter-select="areaChartFilterHandler"
            selectView
          />
        </div>
        <div class="income-page__area-chart-info">
          <div class="income-page__area-chart-info-title">
            {{ capitalizationInfoData.capPrice }}
          </div>
          <div class="income-page__area-chart-info-subtitle">
            {{ capitalizationInfoData.capSubtitle() }}
          </div>
        </div>
        <minimized-area-chart
          class="income-page__area-chart-graph"
          :chartData="capitalizationChartData"
        />
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { updateMoneySum } from '@/helpers/common';
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import TitleSecondary from '@/ui-kit/TitleSecondary/TitleSecondary.vue';
import UiTabs from '@/ui-kit/UiTabs/UiTabs.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
import SummaryTable from '@/components/SummaryTable/SummaryTable.vue';
import SummaryInfo from '@/components/SummaryInfo/SummaryInfo.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';
import InfoChart from '@/components/infoChart/InfoChart.vue';
import MinimizedAreaChart from '@/components/MinimizedAreaChart/MinimizedAreaChart.vue';
import UnitIncome from '@/components/UnitIncome/UnitIncome.vue';
import UserActives from '@/components/UserActives/UserActives.vue';
import StubBlockNoData from '@/components/StubBlockNoData/StubBlockNoData.vue';
import FilterDate from '@/components/FilterDate/FilterDate.vue';
import FilterDropdown from '@/components/FilterDropdown/FilterDropdown.vue';
import ChartLegend from '@/ui-kit/ChartLegend/ChartLegend.vue';
import { POPUP_DATA } from '@/constants/booking';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import translates from './translates';

const CUSTOM_TOOLTIP = {
  custom({ dataPointIndex, seriesIndex, w }) {
    const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

    return `<div class="info-chart__tooltip">
      <span class="info-chart__tooltip-label">${data.name}</span>
      <span class="info-chart__tooltip-sum">${data.y}\u202F₽</span>
    </div>`;
  },
};

const yaxisOptions = {
  tickAmount: 4,
};

export default {
  name: 'IncomePage',
  components: {
    TitleMain,
    TitleSecondary,
    MainSideMenu,
    DashboardLayout,
    UiTabs,
    SummaryTable,
    SummaryInfo,
    InfoChart,
    MinimizedAreaChart,
    UnitIncome,
    StubBlockNoData,
    SecondaryButton,
    UserActives,
    FilterDate,
    FilterDropdown,
    ChartLegend,
  },
  mounted() {
    window.scrollTo(0, 0); // Прокрутка к началу страницы при монтировании компонента
  },
  setup() {
    const store = useStore();
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const incomeState = store.state.income;
    const userState = store.state.user;
    const menuItems = computed(() => userState.sideMenuItems);
    const popupDatas = ref(POPUP_DATA);
    const tooltipData = ref(CUSTOM_TOOLTIP);
    const infoChartYaxisOptions = ref(yaxisOptions);
    const userUnits = computed(() => userState.units);
    const isInvestor = computed(() => userState.isInvestor);
    const isLoading = computed(() => incomeState.isLoading);
    const isLoadingStatistics = computed(() => incomeState.isLoadingStatistics);
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name })));
    const currentUserLocation = computed(() => userState.currentLocation);
    const averageMonthlyIncome = computed(() => (incomeState.averageMonthlyIncome
      ? `${updateMoneySum(incomeState.averageMonthlyIncome)}\u202F₽`
      : '0\u202F₽'));
    const invested = computed(() => (incomeState.invested
      ? `${updateMoneySum(incomeState.invested)}\u202F₽`
      : '0\u202F₽'));
    const totalIncome = computed(() => (incomeState.totalIncome
      ? `${updateMoneySum(incomeState.totalIncome)}\u202F₽`
      : '0\u202F₽'));
    const payback = computed(() => (incomeState.payback
      ? `${incomeState.payback} мес`
      : '–'));
    const loanTermYearMonth = computed(() => (incomeState.loanTermYearMonth));
    const averagePercentForYear = computed(() => (incomeState.averagePercentForYear
      ? `${incomeState.averagePercentForYear}\u202F%`
      : '–'));
    const averagePercentForMonth = computed(() => (incomeState.averagePercentForMonth
      ? `${incomeState.averagePercentForMonth}\u202F%`
      : '–'));
    const monthlyData = computed(() => incomeState.monthlyData);
    const incomeByUnits = computed(() => incomeState.incomeByUnits.map((item) => ({
      ...item,
      monthlyIncome: item.incomes.length ? `${updateMoneySum(item.incomes[0].amount)}\u202F₽` : '0\u202F₽',
      unitName: item.part === '1' ? item.unitName : `${item.part}\u202F${item.unitName}`,
      previousMonthPercent: item.incomes.length ? item.incomes[0].lastMonthDiff : 0,
    })));
    const incomeMonthlyDatesFilter = computed(() => incomeState.monthlyDataDates);
    const selectedMonth = ref('');
    const capitalizationChartData = computed(() => incomeState.currentCapitalizationData.data);
    const capitalizationInfoData = computed(() => ({
      capPrice: incomeState.currentCapitalizationData.capPrice,
      capSubtitle: () => {
        if (
          incomeState.currentCapitalizationData.capDate
          && incomeState.currentCapitalizationData.capName
        ) {
          return `${incomeState.currentCapitalizationData.capDate},
          ${incomeState.currentCapitalizationData.capName}`;
        } if (
          !incomeState.currentCapitalizationData.capDate
          && incomeState.currentCapitalizationData.capName
        ) {
          return `${incomeState.currentCapitalizationData.capName}`;
        }
        return '';
      },
    }));
    const areaChartFilterList = computed(
      () => incomeState.capitalizationGraphicDataAll.map((item) => ({
        id: item.id,
        label: item.name,
      })),
    );
    const incomeChartFilterList = computed(
      () => incomeState.preparedIncomeGraphicData.map((item) => ({
        id: item.id,
        label: item.name,
      })),
    );
    const incomeChartDates = computed(() => Object.values(incomeState.incomeChartDates));
    const incomeChartAllData = computed(() => incomeState.preparedIncomeGraphicData);
    const incomeChartCurrentData = computed(() => incomeState.currentIncomeGraphicData);
    const chartLegend = computed(() => incomeState
      .preparedIncomeGraphicData
      .reduce((acc, item) => (acc.findIndex((accItem) => accItem.label === item.name) === -1
        ? [
          ...acc,
          {
            color: item.color,
            label: item.name,
          },
        ]
        : acc), []));

    const initIncomeData = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/initSideMenuItems');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }
      await store.dispatch('income/initIncome');
    };

    const monthOfIncomeByUnitsSelected = ({ epoch }) => {
      store.dispatch('income/setCurrentMonthOfIncomeByUnits', epoch);
      store.dispatch('income/getIncomeByUnits');
    };

    const incomeDatesFilterHandler = async (dates) => {
      store.dispatch('income/setMonthlyDataDates', {
        dateStart: dates[0],
        dateEnd: dates[1],
      });
      if (incomeState.currentIncomeGraphicData && incomeState.currentIncomeGraphicData.length) {
        await store.dispatch('income/setSelectedIncomeGraphData', incomeState.currentIncomeGraphicData[0].id);
      }
    };
    const downloadHandler = () => {
      store.dispatch('income/downloadIncomeTable', currentUserLocation.value.id);
    };

    const locationChangeHandler = (id) => {
      store.dispatch('user/setCurrentLocation', id);
      initIncomeData();
    };

    const areaChartFilterHandler = (chartData) => {
      store.dispatch('income/setSelectedCapitalizationData', chartData.id);
    };

    const incomeChartFilterHandler = (chartData) => {
      store.dispatch('income/setSelectedIncomeGraphData', chartData.id);
    };

    initIncomeData();

    return {
      t,
      popupDatas,
      userUnits,
      isLoadingStatistics,
      isLoading,
      menuItems,
      userLocations,
      currentUserLocation,
      averageMonthlyIncome,
      invested,
      totalIncome,
      payback,
      averagePercentForYear,
      averagePercentForMonth,
      monthlyData,
      incomeByUnits,
      selectedMonth,
      incomeMonthlyDatesFilter,
      loanTermYearMonth,
      tooltipData,
      monthOfIncomeByUnitsSelected,
      isInvestor,
      incomeDatesFilterHandler,
      downloadHandler,
      locationChangeHandler,
      capitalizationChartData,
      areaChartFilterList,
      areaChartFilterHandler,
      capitalizationInfoData,
      infoChartYaxisOptions,
      incomeChartDates,
      incomeChartAllData,
      chartLegend,
      incomeChartFilterList,
      incomeChartFilterHandler,
      incomeChartCurrentData,
    };
  },
};
</script>

<style lang="scss">
.income-page {
  &__info {
    margin-top: 8px;
    max-width: 740px;
    line-height: 18px;
    font-size: $font-size-regular;
    color: $grey800;
  }

  &__filter-dropdown {
    &--desktop {
      display: block;
    }

    &--mobile {
      display: none;
    }
  }

  &__chart {
    margin-top: -15px;

    &--mobile {
      display: none;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &--is-loading {
        position: relative;
        overflow: hidden;
        pointer-events: none;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 60px;

      .income-page__title {
        margin: 0;
      }

      &-controls {
        display: flex;
        align-items: center;
        box-sizing: border-box;

        &-wrapper {
          display: flex;
          align-items: center;

          .secondary-button {
            margin-left: 12px;
          }

          .filter-date {
            margin-left: 12px;
          }
        }
      }
    }
  }

  &__units {
    margin-top: 50px;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-body {
      border-bottom: 1px solid $grey200;
    }

    &-item {
      width: 338px;
      padding: 15px 0 25px;
      border-right: 1px solid $grey200;
    }
  }

  &__area-chart {
    margin-top: 60px;
    display: flex;
    flex-direction: column;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-info {
      display: flex;
      flex-direction: column;
      margin-top: 44px;

      &-title {
        color:$main-black;
        font-size: $font-size-title-large;
        line-height: 24px;
        font-weight: $font-weight-regular;
      }

      &-subtitle {
        margin-top: 7px;
        color: $grey700;
        font-size: $font-size-regular;
        font-weight: $font-weight-medium;
        line-height: 18px;
      }
    }

    &-graph {
      margin-top: 25px;
    }
  }

  &__stub {
    margin-top: 24px;
  }

  &__loader {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(180, 180, 180, 0.25);

    &-icon {
      width: 23px;
      height: 23px;
      background-image: url(../assets/loader.svg);
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  &__summary-table {
    .summary-table__block {
      flex: 1;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .income-page {
    &__summary-table {
      .summary-table__block {
        border-top: 1px solid $grey200;

        &:first-child {
          border-top: none;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .income-page {
    &__filter-dropdown {
      &--desktop {
        display: none;
      }

      &--mobile {
        display: block;
      }
    }

    &__chart {
      &--desktop {
        display: none;
      }

      &--mobile {
        display:block;
      }

      &-header {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        &-controls {
          margin-top: 22px;
          overflow: scroll;
          -ms-overflow-style: none;
          scrollbar-width: none;
          width: 100%;

          &-wrapper {
            flex-direction: row-reverse;
            justify-content: flex-end;
            flex-shrink: 0;
          }

          &::-webkit-scrollbar {
            display: none;
          }

          .filter-date {
            margin: 0;
          }
        }
      }
    }

    &__units-header {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .title-secondary {
        margin-bottom: 16px;
      }
    }

    &__area-chart {
      &-header {
        flex-direction: column;
        align-items: flex-start;

        .filter-dropdown {
          margin-top: 16px;
          width: 320px;
        }
      }
    }

    &__chart-wrapper {
      .chart-legend {
        display: none;
      }
    }
  }
}
</style>
