<template>
  <div class="unit-card">
    <div class="unit-card__row">
      <div class="unit-card__carousel-wrapper">
        <Carousel :settings="carouselSettings">
          <template #slides>
            <Slide
              v-for="slide in imgArray"
              :key="slide.id"
            >
              <img
                :src="slide.src"
                alt=""
              >
            </Slide>
          </template>
          <template
            #addons
            v-if="imgArray.length > 1"
          >
            <Navigation/>
            <Pagination/>
          </template>
        </Carousel>
      </div>
      <div class="unit-card__upper-block unit-card__upper-block--only-tablet">
        <div class="unit-card__name-block">
          <div class="unit-card__name-inner-block">
            <p class="unit-card__pre-title">{{ unitType }}</p>
            <title-main
              class="unit-card__title"
              :text="unitName"
            />
          </div>
        </div>
        <div class="unit-card__capacity-block">
          <span class="unit-card__capacity-label">{{ t('unit') }}</span>
          <div class="unit-card__capacity-inner-wrapper">
            <div class="unit-card__capacity-inner-block">
              <img
                class="unit-card__capacity-icon"
                src="@/assets/guestIcon.svg"
                alt=""
              >
              <p class="unit-card__capacity-text-area">{{ guestCount }}</p>
            </div>
            <div class="unit-card__capacity-inner-block">
              <img
                class="unit-card__capacity-icon"
                src="@/assets/areaIcon.svg"
                alt=""
              >
              <p class="unit-card__capacity-text-area">{{ formattedArea }}</p>
            </div>
          </div>
        </div>
        <more-link
          class="unit-card__info-link"
          :label="t('more')"
          :path="unitLink"
        />
      </div>
    </div>
    <div class="unit-card__info-wrapper">
      <div class="unit-card__upper-block">
        <div class="unit-card__name-block">
          <div class="unit-card__name-inner-block">
            <p class="unit-card__pre-title">{{ unitType }}</p>
            <title-main
              class="unit-card__title"
              :text="unitName"
            />
          </div>
          <more-link
            class="unit-card__info-link unit-card__info-link--desktop"
            :label="t('more')"
            :path="unitLink"
          />
        </div>
        <div class="unit-card__capacity-block">
          <span class="unit-card__capacity-label">{{ t('unit') }}</span>
          <div class="unit-card__capacity-inner-wrapper">
            <div class="unit-card__capacity-inner-block">
              <img
                class="unit-card__capacity-icon"
                src="@/assets/guestIcon.svg"
                alt=""
              >
              <p class="unit-card__capacity-text-area">{{ guestCount }}</p>
            </div>
            <div class="unit-card__capacity-inner-block">
              <img
                class="unit-card__capacity-icon"
                src="@/assets/areaIcon.svg"
                alt=""
              >
              <p class="unit-card__capacity-text-area">{{ formattedArea }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="unit-card__lower-block">
        <unit-income
          class="unit-card__income-block"
          :headerText="t('investment')"
          :priceText="formattedSum"
        />
        <unit-income
          class="unit-card__income-block"
          :headerText="t('dateInvestment')"
          :priceText="formattedDate"
        />
        <div class="unit-card__unit-state-block">
          <div class="unit-card__unit-state-inner-block">
            <p class="unit-card__unit-state-label">{{ t('stage') }}</p>
            <p class="unit-card__unit-state-info">{{ currentStatus }}</p>
          </div>
          <div
            v-if="false"
            class="unit-card__unit-state-inner-block"
          >
            <p class="unit-card__unit-state-label">{{ t('launch') }}</p>
            <p class="unit-card__unit-state-info">{{ willBeReady }}</p>
          </div>
        </div>
      </div>
      <more-link
        class="unit-card__info-link unit-card__info-link--mobile"
        :label="t('more')"
        :path="unitLink"
      />
    </div>
  </div>
</template>

<script>
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import MoreLink from '@/ui-kit/MoreLink/MoreLink.vue';
import UnitIncome from '@/components/UnitIncome/UnitIncome.vue';
import { updateMoneySum } from '@/helpers/common';

import 'vue3-carousel/dist/carousel.css';
import {
  Carousel, Slide, Pagination, Navigation,
} from 'vue3-carousel';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import translate from './translate';

export default {
  name: 'UnitCard',
  components: {
    TitleMain,
    MoreLink,
    UnitIncome,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    unitType: {
      type: String,
      default: '',
    },
    unitName: {
      type: String,
      default: '',
    },
    unitLink: {
      type: String,
      default: '',
    },
    guestCount: {
      type: String,
      default: '',
    },
    areaCount: {
      type: String,
      default: '',
    },
    investedSum: {
      type: String,
      default: '',
    },
    dateOfInvest: {
      type: String,
      default: '',
    },
    currentStatus: {
      type: String,
      default: '',
    },
    willBeReady: {
      type: String,
      default: '',
    },
    imgArray: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translate.en);
    mergeLocaleMessage('ru', translate.ru);
    const carouselSettings = {
      itemsToShow: 1,
      wrapAround: true,
      snapAlign: 'center',
    };
    const formattedDate = computed(() => new Date(props.dateOfInvest)
      .toLocaleString().split(',')[0]);
    const formattedSum = computed(() => props.investedSum
      ? `${updateMoneySum(props.investedSum)}\u202F₽` : '');
    const formattedArea = computed(() => props.areaCount
      ? `${props.areaCount} м2` : '');

    return {
      t,
      carouselSettings,
      formattedDate,
      formattedSum,
      formattedArea,
    };
  },
};
</script>

<style lang="scss">
.carousel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  --vc-pgn-width: 6px;
  --vc-pgn-height: 6px;
  --vc-pgn-margin: 4px;
  --vc-pgn-border-radius: 45%;
  --vc-pgn-background-color: rgba(255,255,255,0.4);
  --vc-pgn-active-color: rgba(93, 195, 151, 0.4);
}

.carousel__next, .carousel__prev {
  display: none;
  font-size:20px;
  background: $white;
  border-radius: 100px;
  color: $grey900;
  width: 40px;
  height: 40px;
  transition: 0.2s;
  &:hover {
    color: $blue500;
    background: $blue100;
  }
}

.carousel__viewport {
  width: 100%;
  height: 100%;
  position: relative;
}

.carousel__pagination {
  display: none;
  margin-bottom: 10px;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.carousel__slide {
  img {
    width: 100%;
    object-fit: cover;
  }
}

.carousel__track {
  height: 100%;
}

.carousel__slide img {
  min-height: 100%;
}

.unit-card {
  display: flex;

  &__row {
    display: flex;
    width: fit-content;
  }

  &__carousel-wrapper {
    width: 389px;
    height: 242px;
    border-radius: 12px;
    border: 1px solid $grey300;
    position: relative;
    overflow: hidden;
    isolation: isolate;
    transition: 0.2s;
    cursor: pointer;
    &:hover .carousel__prev, &:hover .carousel__next, &:hover .carousel__pagination{
      display: flex;
    }
  }

  &__info-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 0 0 25px;
  }

  &__upper-block {
    display: flex;
    flex-direction: column;

    &--only-tablet {
      display: none;
    }
  }

  &__name-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
  }

  &__name-inner-block {
    display: flex;
    flex-direction: column;
  }

  &__pre-title {
    margin: 0;
    padding: 0;
    font-size: $font-size-tiny;
    font-weight: $font-weight-half-bold;
    line-height: 16px;
    color: $main-black;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  &__title {
    margin: 7px 0 0 0;
    color: $main-black;
  }

  &__info-link {
    &--mobile {
      display: none;
    }
  }

  &__capacity {
    &-block {
      display: flex;
      margin: 44px 0 0 0;
    }

    &-label {
      display: none;
    }

    &-inner {
      &-wrapper {
        display: flex;
      }
      &-block {
        display: flex;
        align-items: center;

        &:last-child {
          margin: 0 0 0 18px;
        }
      }
    }

    &-icon {
      width: 12px;
      height: 12px;
    }

    &-text {
      &-area {
        margin: 0 0 0 5px;
        padding: 0;
        font-size: $font-size-regular;
        font-weight: $font-weight-medium;
        line-height: 18px;
        color: $grey800;
      }
    }
  }

  &__lower-block {
    display: flex;
    margin: 11px 0 0 0;
    border-top: 1px solid $grey200;
    border-bottom: 1px solid $grey200;
  }

  &__income-block {
    padding: 16px 0 23px 12px;
    min-width: 248px;
    width: 33.33%;
    border-right: 1px solid $grey200;
    flex-grow: 1;
    flex-shrink: 0;

    &:first-child {
      padding-left: 0;
    }
  }

  &__unit-state-block {
    display: flex;
    width: 33.33%;
    flex-direction: column;
    padding: 16px 0 23px 12px;
  }

  &__unit-state-inner-block {
    display: flex;

    &:nth-child(2) {
      margin: 8px 0 0 0;
    }
  }

  &__unit-state-label {
    margin: 0;
    padding: 0;
    font-size: $font-size-regular;
    font-weight: $font-weight-medium;
    line-height: 18px;
    color: $main-black;
    width: 51px;
  }

  &__unit-state-info {
    margin: 0 0 0 18px;
    padding: 0;
    font-size: $font-size-regular;
    font-weight: $font-weight-medium;
    line-height: 18px;
    color: $grey800;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .unit-card {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__carousel-wrapper {
      width: 50%;
      height: 221px;
    }

    &__row {
      width: 100%;
    }

    &__upper-block {
      display: none;

      &--only-tablet {
        display: flex;
        width: 50%;
        padding-left: 22px;
      }
    }

    &__capacity {
      &-block {
        margin: 27px 0 0 0;
      }

      &-inner {
        &-wrapper {
          display: flex;
        }
      }
    }

    &__info-link {
      margin-top: auto;
      padding: 0;
    }

    &__info-wrapper {
      margin-left: 0;
    }

    &__lower-block {
      margin: 16px 0 0 0;
    }

    &__income-block {
      min-width: initial;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .unit-card {
    padding: 0 16px;

    &__carousel-wrapper {
      width: 100%;
      height: 222px;
    }

    &__row {
      width: 100%;
    }

    &__info-wrapper {
      width: 100%;
      margin-left: 0;
      margin-top: 18px;
    }

    &__upper-block {
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 16px;

      &--only-tablet {
        display: none;
      }
    }

    &__name-inner-block {
      .unit-card__pre-title {
        display: none;
      }
    }

    &__capacity {
      &-block {
        margin-top: 0;
        justify-content: space-between;
      }

      &-label {
        display: block;
        font-weight: $font-weight-medium;
        font-size: $font-size-tiny;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $grey800;
      }

      &-text {
        &-area {
          font-size: $font-size-small;
        }
      }

      &-inner {
        &-block {
          &:last-child{
            margin: 0;
          }
        }

        &-wrapper {
          gap: 0 12px;
        }
      }
    }

    &__info-link {
      &--desktop {
        display: none;
      }

      &--mobile {
        display: block;
        padding-left: 0;
      }
    }

    &__lower-block {
      margin-top: 0;
      flex-direction: column;
      border-bottom: none;
    }

    &__income-block {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid $grey200;
      padding: 25px 0 24px 0;

      .unit-income {
        &__header {
          .info-header {
            font-size: $font-size-small;
          }
        }

        &__info-wrapper {
          margin: 20px 0 0 0;
        }
      }
    }

    &__unit-state {
      &-block {
        width: 100%;
        padding: 24px 0;
      }

      &-label, &-info {
        font-size: $font-size-small;
      }
    }
  }
}
</style>
