<template>
  <info-block
    class="stub-preamble"
  >
    <template #BlockTitle>
      Схема взаимодействия юридических лиц группы компаний dewis
    </template>
    <template #BlockText>
      <ul>
        <li>Юридические лица группы компаний</li>
        <li>Зоны ответственности каждого юридического лица</li>
        <li>Производственный цикл гостиничных комплексов VIEW GA</li>
      </ul>
    </template>
    <template #BlockButton>
      <secondary-button
        blue
        label="Открыть схему"
        @click="openLink"
      >
        <template #iconBefore>
          <svg 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path 
              d="M5.36188 12.527L4.10308 11.8976L3.47368 10.6388C3.45061 10.6001 3.41786 10.568
              3.37866 10.5457C3.33946 10.5234 3.29515 10.5117 3.25006 10.5117C3.20497 10.5117
              3.16065 10.5234 3.12145 10.5457C3.08225 10.568 3.04951 10.6001 3.02643
              10.6388L2.39703 11.8976L1.13823 12.527C1.0967 12.5478 1.06176 12.5797 1.03735
              12.6192C1.01293 12.6587 1 12.7042 1 12.7506C1 12.7971 1.01293 12.8426 1.03735
              12.8821C1.06176 12.9216 1.0967 12.9535 1.13823 12.9742L2.39703 13.6036L3.02643
              14.8624C3.04719 14.904 3.07911 14.9389 3.11861 14.9633C3.15811 14.9877 3.20362
              15.0007 3.25006 15.0007C3.29649 15.0007 3.34201 14.9877 3.38151 14.9633C3.42101
              14.9389 3.45292 14.904 3.47368 14.8624L4.10308 13.6036L5.36188 12.9742C5.40342 
              12.9535 5.43835 12.9216 5.46277 12.8821C5.48718 12.8426 5.50012 12.7971 5.50012 
              12.7506C5.50012 12.7042 5.48718 12.6587 5.46277 12.6192C5.43835 12.5797 5.40342 
              12.5478 5.36188 12.527Z"
              fill="white"
            />
            <path 
              d="M14.8619 10.527L12.9363 9.56435L11.9737 7.6388C11.9506 7.60006 11.9179
              7.56798 11.8787 7.54571C11.8395 7.52343 11.7951 7.51172 11.7501 7.51172C11.705
              7.51172 11.6607 7.52343 11.6215 7.54571C11.5823 7.56798 11.5495 7.60006 11.5264
              7.6388L10.5638 9.56435L8.63823 10.527C8.5967 10.5478 8.56176 10.5797 8.53735
              10.6192C8.51293 10.6587 8.5 10.7042 8.5 10.7506C8.5 10.7971 8.51293 10.8426
              8.53735 10.8821C8.56176 10.9216 8.5967 10.9535 8.63823 10.9742L10.5638
              11.9369L11.5264 13.8624C11.5472 13.904 11.5791 13.9389 11.6186 13.9633C11.6581
              13.9877 11.7036 14.0007 11.7501 14.0007C11.7965 14.0007 11.842 13.9877 11.8815
              13.9633C11.921 13.9389 11.9529 13.904 11.9737 13.8624L12.9363 11.9369L14.8619
              10.9742C14.9034 10.9535 14.9384 10.9216 14.9628 10.8821C14.9872 10.8426 15.0001
              10.7971 15.0001 10.7506C15.0001 10.7042 14.9872 10.6587 14.9628 10.6192C14.9384
              10.5797 14.9034 10.5478 14.8619 10.527Z"
              fill="white"
            />
            <path 
              d="M10.3619 5.527L7.43633 4.06435L5.97368 1.1388C5.95061 1.10006 5.91786 1.06798
              5.87866 1.04571C5.83946 1.02343 5.79515 1.01172 5.75006 1.01172C5.70497 1.01172
              5.66065 1.02343 5.62145 1.04571C5.58225 1.06798 5.54951 1.10006 5.52643
              1.1388L4.06378 4.06435L1.13823 5.527C1.0967 5.54776 1.06176 5.57967 1.03735
              5.61917C1.01293 5.65867 1 5.70419 1 5.75062C1 5.79706 1.01293 5.84257 1.03735
              5.88207C1.06176 5.92157 1.0967 5.95349 1.13823 5.97425L4.06378 7.4369L5.52643
              10.3624C5.54719 10.404 5.57911 10.4389 5.61861 10.4633C5.65811 10.4877 5.70362
              10.5007 5.75006 10.5007C5.79649 10.5007 5.84201 10.4877 5.88151 10.4633C5.92101
              10.4389 5.95292 10.404 5.97368 10.3624L7.43633 7.4369L10.3619 5.97425C10.4034
              5.95349 10.4384 5.92157 10.4628 5.88207C10.4872 5.84257 10.5001 5.79706 10.5001
              5.75062C10.5001 5.70419 10.4872 5.65867 10.4628 5.61917C10.4384 5.57967 10.4034
              5.54776 10.3619 5.527Z"
              fill="white"
            />
          </svg>
        </template>
      </secondary-button>
    </template>
    <template #BlockImage>
      <img
        class="stub-preamble__img"
        src="@/assets/stub-preamble.svg"
        alt=""
      />
    </template>
  </info-block>
</template>

<script>
import { ref } from 'vue';
import InfoBlock from '../InfoBlock/InfoBlock.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';

export default {
  name: 'StubPreamble',
  components: {
    InfoBlock,
    SecondaryButton,
  },
  props: {
    link: {
      type: String,
      default: '',
    }
  },
  setup(props) {
    const linkFromProps = ref(props.link);
    const openLink = () => {
      window.open(linkFromProps.value, '_blank');
    };

    return {
      openLink,
    }
  }
}
</script>

<style lang="scss">
.stub-preamble {
  background-color: $blue100;
  padding: 28px 95px 28px 32px;
  justify-content: space-between;

  .info-block {
    &__column {
      max-width: 526px;
    }

    &__title {
      font-weight: $font-weight-medium;
      color: $main-black;
      font-size: $font-size-title-mid-small;
      line-height: 26px;
    }

    &__text {
      ul {
        margin: 0;
        padding: 0 0 0 20px;
        color: $grey800;
        font-size: $font-size-regular;
        font-weight: $font-weight-regular;
        line-height: 18px;
      }

      li {
        margin: 3px 0 0 0;
        padding: 0;
      }
    }

    &__btn {
      margin-top: 22px;

      .secondary-button {
        padding: 12px 16px;
      }
    }
  }

  &__img {
    margin-left: 40px;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .stub-preamble {
    padding: 28px 32px;

    &__img {
      max-width: 240px;
    }

    .info-block {
      &__column {
        max-width: none;
        flex-shrink: 1;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .stub-preamble {
    padding: 24px 29px;

    &__img {
      display: none;
    }

    .info-block {
      &__title {
        font-size: $font-size-medium;
        line-height: 22px;
      }

      &__text {
        ul {
          font-size: $font-size-small;
          line-height: 16px;
        }
      }

      &__btn {
        width: 100%;

        .secondary-button {
          width: 100%;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>