<template>
    <Popper
      :placement="placement"
      class="popup-wrapper"
    >
      <slot></slot>
      <template #content>
        <div class="popup-wrapper__content-area">
          <slot name="popperContent"></slot>
        </div>
      </template>
    </Popper>
</template>

<script>
import Popper from 'vue3-popper';

export default {
  name: 'PopupWrapper',
  components: {
    Popper,
  },
  props: {
    placement: {
      type: String,
      default: 'auto',
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-wrapper {
  --popper-theme-background-color: #fff;
  --popper-theme-background-color-hover: #fff;
  --popper-theme-border-width: 1px;
  --popper-theme-border-color: #e0e3e7;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 8px;
  --popper-theme-padding: 0;
  --popper-theme-box-shadow: 0 7px 13px 0 rgb(172 176 194 / 37%);
  margin-right: -11px !important;

  &__content-area {
     display: flex;
     flex-direction: column;
   }
}

</style>
