const messages = {
  en: {
    investor: 'investor',
    settings: 'Account settings',
    actives: 'My assets',
    documents: 'My documents',
    exit: 'Log out',
    news: 'News',
    faq: 'FAQ',
    unit: 'unit | units | units',
    logIn: 'Log in',
    menuMain: 'Summary',
    menuIncome: 'Your income',
    menuTimeline: 'Project timeline',
    menuReports: 'Project reports',
    menuUnits: 'Room inventory',
    menuBooking: 'Reservations',
    menuIncomeOutcome: 'Income and expenses',
    menuPeriphery: 'Outbuildings',
    menuControls: 'Quality control',
    menuCameras: 'Cameras',
    menuChecks: 'Checks',
    menuInventory: 'Inventory',
    menuTooltipHeaderUnavailable: 'Section unavailable',
    menuTooltipTextUnavailable: 'You don\'t have any {msg}, so this section is not available to you.',
    weekAgo: 'A week ago',
    twoWeekAgo: 'Two week ago',
    monthAgo: 'A month ago',
    twoMonthAgo: 'Two months ago',
    inMonth: 'In a month',
    inTwoMonths: 'In two months',
    source: 'Source',
    today: 'Today',
    cancel: 'Cancel',
    send: 'Send',
    selectedDate: 'Selected date',
    change: 'Change',
    password: 'Password',
    changePassword: 'Change password',
    save: 'Save',
    notSpecified: 'Not specified',
    needSign: 'There\'s a document that needs to be signed',
    bookingSources: {
      reception: 'Reception',
      call: 'Phone call',
      sales: 'From the sales channel',
      web: 'Hotel\'s website',
      fax: 'Fax',
      email: 'Email',
      vk: 'VK',
      mobile: 'Mobile website',
      fb: 'Facebook',
      ta: 'TripAdvisor',
      extranet: 'Booking from an extranet',
      ya: 'Yandex',
      mobileExtranet: 'Mobile extranet',
    },
    lenderMenuTooltipHeaderUnavailabe: 'This section is only for users with an investment agreement',
    lenderMenuTooltipTextUnavailable: 'Contact sales to learn more.',
  },
  ru: {
    investor: 'инвестор',
    settings: 'Настройки аккаунта',
    actives: 'Мои активы',
    documents: 'Мои документы',
    send: 'Отправить',
    exit: 'Выйти',
    news: 'Новости',
    faq: 'FAQ',
    unit: 'юнит | юниты | юнитов',
    logIn: 'Вход',
    menuMain: 'Главная',
    menuIncome: 'Ваши доходы',
    menuTimeline: 'Таймлайн проекта',
    menuReports: 'Отчеты проекта',
    menuUnits: 'Номерной фонд',
    menuBooking: 'Бронирования',
    menuIncomeOutcome: 'Доходы и расходы',
    menuPeriphery: 'Периферия',
    menuControls: 'Контроль качества',
    menuCameras: 'Камеры',
    menuChecks: 'Проверки',
    menuInventory: 'Инвентаризации',
    menuTooltipHeaderUnavailable: 'Раздел недоступен',
    menuTooltipTextUnavailable: 'У вас нет {msg}, поэтому этот раздел вам недоступен.',
    weekAgo: 'Неделя назад',
    twoWeekAgo: 'Две недели назад',
    monthAgo: 'Месяц назад',
    twoMonthAgo: 'Два месяца назад',
    inMonth: 'Через месяц',
    inTwoMonths: 'Через два месяца',
    source: 'Источник',
    today: 'Сегодня',
    selectedDate: 'Выбранное число | Выбранная дата',
    change: 'Изменить',
    changePassword: 'Изменить пароль',
    password: 'Пароль',
    cancel: 'Отменить',
    save: 'Сохранить',
    notSpecified: 'Не указано',
    needSign: 'Есть документ, который надо подписать',
    bookingSources: {
      reception: 'От стойки',
      call: 'По звонку',
      sales: 'Из канала продаж',
      web: 'Сайт гостиницы',
      fax: 'Факс',
      email: 'Email',
      vk: 'ВКонтакте',
      mobile: 'Мобильная версия сайта',
      fb: 'Facebook',
      ta: 'TripAdvisor',
      extranet: 'Бронирование из экстранета',
      ya: 'Yandex',
      mobileExtranet: 'Мобильный экстранет',
    },
    lenderMenuTooltipHeaderUnavailabe: 'Раздел только для пользователей с инвестиционным договором',
    lenderMenuTooltipTextUnavailable: 'Свяжитесь с отделом продаж, чтобы узнать больше.',
  },
};

export default {
  legacy: false,
  globalInjection: true,
  fallbackLocale: 'en',
  messages,
};
