export const LOCATION_INFO_DATA = {
  1: {
    locationName: 'locationsInfo.volochaevka.title',
    locationAbout: 'locationsInfo.volochaevka.about',
    locationPlace: 'locationsInfo.volochaevka.place',
    locationUnit: {
      livingSpace: [
        {
          type: 'locationsInfo.unitTypes.standart',
          quantity: 2,
        },
        {
          type: 'locationsInfo.unitTypes.halfLux',
          quantity: 9,
        },
        {
          type: 'locationsInfo.unitTypes.lux',
          quantity: 1,
        },
      ],
      periphery: [
        {
          type: 'locationsInfo.peripheryTypes.banya',
          quantity: 2,
        },
      ],
    },
    locationInfrastructure: [
      {
        name: 'locationsInfo.volochaevka.infrastructure[0]',
      },
      {
        name: 'locationsInfo.volochaevka.infrastructure[1]',
      },
      {
        name: 'locationsInfo.volochaevka.infrastructure[2]',
      },
      {
        name: 'locationsInfo.volochaevka.infrastructure[3]',
      },
      {
        name: 'locationsInfo.volochaevka.infrastructure[4]',
      },
      {
        name: 'locationsInfo.volochaevka.infrastructure[5]',
      },
      {
        name: 'locationsInfo.volochaevka.infrastructure[6]',
      },
      {
        name: 'locationsInfo.volochaevka.infrastructure[7]',
      },
    ],
  },
  2: {
    locationName: 'locationsInfo.simagino.title',
    locationAbout: 'locationsInfo.simagino.about',
    locationPlace: 'locationsInfo.simagino.place',
    locationUnit: {
      hasMultipleArea: true,
      areas: [
        {
          areaName: 'locationsInfo.simagino.area.forestArea',
          units: {
            livingSpace: [
              {
                type: 'locationsInfo.unitTypes.halfLux',
                quantity: 10,
              },
            ],
            periphery: [
              {
                type: 'locationsInfo.peripheryTypes.banyaComplex',
                quantity: 2,
              },
            ],
          },
        },
        {
          areaName: 'locationsInfo.simagino.area.lakeArea',
          units: {
            periphery: [
              {
                type: 'locationsInfo.peripheryTypes.patio',
                quantity: 6,
              },
              {
                type: 'locationsInfo.peripheryTypes.pantoonBanya',
                quantity: 6,
              },
            ],
          },
        },
      ],
    },
    locationInfrastructure: [
      {
        name: 'locationsInfo.simagino.infrastructure[0]',
      },
      {
        name: 'locationsInfo.simagino.infrastructure[1]',
      },
      {
        name: 'locationsInfo.simagino.infrastructure[2]',
      },
      {
        name: 'locationsInfo.simagino.infrastructure[3]',
      },
      {
        name: 'locationsInfo.simagino.infrastructure[4]',
      },
      {
        name: 'locationsInfo.simagino.infrastructure[5]',
      },
      {
        name: 'locationsInfo.simagino.infrastructure[6]',
      },
      {
        name: 'locationsInfo.simagino.infrastructure[7]',
      },
    ],
  },
  3: {
    locationName: 'locationsInfo.leskolovo.title',
    locationAbout: 'locationsInfo.leskolovo.about',
    locationPlace: 'locationsInfo.leskolovo.place',
    locationUnit: {
      livingSpace: [
        {
          type: 'locationsInfo.unitTypes.halfLux',
          quantity: 12,
        },
        {
          type: 'locationsInfo.unitTypes.lux',
          quantity: 12,
        },
      ],
      periphery: [
        {
          type: 'locationsInfo.peripheryTypes.spaModule',
          quantity: 12,
        },
        {
          type: 'locationsInfo.peripheryTypes.grillPatio',
          quantity: 12,
        },
      ],
    },
    locationInfrastructure: [
      {
        name: 'locationsInfo.leskolovo.infrastructure[0]',
      },
      {
        name: 'locationsInfo.leskolovo.infrastructure[1]',
      },
      {
        name: 'locationsInfo.leskolovo.infrastructure[2]',
      },
      {
        name: 'locationsInfo.leskolovo.infrastructure[3]',
      },
      {
        name: 'locationsInfo.leskolovo.infrastructure[4]',
      },
      {
        name: 'locationsInfo.leskolovo.infrastructure[5]',
      },
    ],
  },
};
