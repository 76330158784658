<template>
  <div class="media-block">
    <div
      class="media-block__cover"
      @click="toggleModal"
    >
      <img
        class="media-block__cover-img"
        :src="cover"
        :alt="title"
      >
      <span
        v-if="date.length"
        class="media-block__cover-date"
      >
        {{ date }}
      </span>
    </div>
    <div
      v-if="title.length"
      class="media-block__title"
      @click="toggleModal"
    >
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0.949707C3.58862 0.949707 0 4.53833 0 8.94971C0 13.3611 3.58862 16.9497 8
          16.9497C12.4114 16.9497 16 13.3611 16 8.94971C16 4.53833 12.4114 0.949707 8
          0.949707ZM12.0547 7.25439L7.72131 11.5876C7.59131 11.7177 7.42065 11.7831 7.25
          11.7831C7.07935 11.7831 6.90869 11.7177 6.77869 11.5876L4.61206 9.42102C4.35132
          9.1604 4.35132 8.73901 4.61206 8.47839C4.87268 8.21765 5.29395 8.21765 5.55469
          8.47839L7.25 10.1737L11.1121 6.31177C11.3727 6.05103 11.7939 6.05103 12.0547
          6.31177C12.3153 6.57239 12.3153 6.99365 12.0547 7.25439Z"
          fill="#2BBE9B"
        />
      </svg>
      <span>{{ title }}</span>
    </div>
  </div>
  <modal-window
    :is-shown="isOpenModal"
    is-media
    @close-window="toggleModal"
  >
    <media-gallery
      :slides="slides"
    />
  </modal-window>
</template>

<script>
import { ref } from 'vue';
import ModalWindow from '@/components/ModalWindow/ModalWindow.vue';
import MediaGallery from '@/components/MediaGallery/MediaGallery.vue';

export default {
  name: 'media-block',
  components: {
    MediaGallery,
    ModalWindow,
  },
  props: {
    cover: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    slides: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const isOpenModal = ref(false);
    const toggleModal = () => {
      isOpenModal.value = !isOpenModal.value;
    };

    return {
      toggleModal,
      isOpenModal,
    };
  },
};
</script>

<style lang="scss">
.media-block {
  width: 100%;

  &__cover {
    position: relative;
    width: 100%;
    height: 198px;
    border: 1px solid $grey300;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;

    &-img {
      width: 100%;
      min-width: 100%;
      min-height: 100%;
    }

    &-date {
      position: absolute;
      top: 8px;
      left: 8px;
      display: flex;
      align-items: center;
      height: 18px;
      padding: 0 10px;
      border: 1px solid $grey200;
      border-radius: 30px;
      color: $grey800;
      font-size: $font-size-small;
      background: $grey100;
    }
  }

  &__title {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    padding-right: 24px;
    color: $main-black;
    font-size: $font-size-regular;
    line-height: 18px;
    cursor: pointer;

    svg {
      display: inline-block;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
}
</style>
