import { FIELD_MESSAGES, USER_ROLE_KEY, USER_TOKEN_KEY } from '@/constants/auth';
import { sendRequest } from '@/helpers/request';
import {
  API_REGISTRATION,
  API_LOGIN,
  API_GET_USER_LOCATIONS,
  API_PROFILE_SETTINGS,
  API_GET_USER_UNITS,
  API_PASSWORD_RECOVERY,
  API_SET_PASSWORD,
  API_PASSWORD_CHANGE,
  API_GET_USER_UNITS_FULL_INFO,
  API_GET_FAQ,
  API_SEND_QUESTION,
  API_CAPTCHA_VERIFY,
} from './api_pathes';

async function signUp(payload = {}) {
  if (!payload.email && !payload.phone) {
    throw new Error('[USER_AUTH] Have no payload for registration.');
  }

  try {
    return await sendRequest(API_REGISTRATION, payload);
  } catch (error) {
    console.error('[USER_AUTH] Something went wrong with request to registration API', error);
    return {};
  }
}

async function setPassword(payload, origin) {
  if (!payload || !payload.link || !payload.password || !origin) {
    throw new Error('[USER_AUTH] Have not enough data for password registration.');
  }

  try {
    return await sendRequest(API_SET_PASSWORD[origin], payload);
  } catch (error) {
    console.error('[USER_AUTH] Something went wrong with request to registration password API', error);
    return {};
  }
}

async function passwordRecover(payload = {}) {
  if (!payload.email) {
    throw new Error('[USER_AUTH] Have no payload for password recovery.');
  }

  try {
    return await sendRequest(API_PASSWORD_RECOVERY, payload);
  } catch (error) {
    console.error('[USER_AUTH] Something went wrong with request to password recovery API', error);
    return {};
  }
}

async function passwordChange(payload = {}) {
  if (!payload.oldPassword || !payload.newPassword) {
    throw new Error('[USER_AUTH] Have no payload for password change.');
  }

  try {
    return await sendRequest(API_PASSWORD_CHANGE, payload);
  } catch (error) {
    console.error('[USER_AUTH] Something went wrong with request to password change API', error);
    return {};
  }
}

async function login(payload = {}) {
  if (!payload.login || !payload.password) {
    throw new Error('[USER_AUTH] Have no payload for login.');
  }

  try {
    const resp = await sendRequest(API_LOGIN, payload);
    if (resp.ok) {
      localStorage.setItem(USER_TOKEN_KEY, resp.data.token);
      localStorage.setItem(USER_ROLE_KEY, JSON.stringify(resp.data.roles));
      return resp;
    }
    return {
      ok: false,
      data: {
        message: FIELD_MESSAGES.errors.loginError,
      },
    };
  } catch (error) {
    console.error('[USER_AUTH] Something went wrong with request to login API', error);
    return {};
  }
}

async function getUserLocations() {
  try {
    const resp = await sendRequest(API_GET_USER_LOCATIONS, null, 'GET');
    if (resp.ok) {
      return resp;
    }

    return [];
  } catch (error) {
    console.error('[USER_LOCATIONS]: Something went wrong with request to get user locations API', error);
    return [];
  }
}

async function getUserUnits() {
  try {
    const resp = await sendRequest(API_GET_USER_UNITS, null, 'GET');
    if (resp.ok) {
      return resp;
    }

    return [];
  } catch (error) {
    console.error('[USER_LOCATIONS]: Something went wrong with request to get user units API', error);
    return [];
  }
}

async function getUserUnitsFullInfo() {
  try {
    const resp = await sendRequest(API_GET_USER_UNITS_FULL_INFO, null, 'GET');
    if (resp.ok) {
      return resp;
    }

    return [];
  } catch (error) {
    console.error('[USER_LOCATIONS]: Something went wrong with request to get full info of user units API', error);
    return [];
  }
}

function logout() {
  localStorage.removeItem(USER_TOKEN_KEY);
}

async function getUserProfileSettings() {
  try {
    const resp = await sendRequest(API_PROFILE_SETTINGS, null, 'GET');
    if (resp.ok) {
      return resp;
    }

    return null;
  } catch (error) {
    console.error('[USER_LOCATIONS]: Something went wrong with request to get user profile settings', error);
    return null;
  }
}

async function updateUserProfileSettings(settings) {
  try {
    const resp = await sendRequest(API_PROFILE_SETTINGS, settings, 'POST');
    if (resp.ok) {
      return resp;
    }

    return null;
  } catch (error) {
    console.error('[USER_LOCATIONS]: Something went wrong with request to update profile settings', error);
    return null;
  }
}

async function getFaq() {
  try {
    const resp = await sendRequest(API_GET_FAQ, null, 'GET');
    if (resp.ok) {
      return resp.data.faq;
    }

    return null;
  } catch (error) {
    console.error('[USER_LOCATIONS]: Something went wrong with request to get FAQ', error);
    return null;
  }
}

async function sendQuestion(payload) {
  try {
    await sendRequest(API_SEND_QUESTION, payload, 'POST');
    return true;
  } catch (error) {
    console.error('[FAQ]: Something went wrong with request to send FAQ', error);
    return null;
  }
}

async function captchaVerify(response) {
  try {
    const resp = await sendRequest(API_CAPTCHA_VERIFY, {
      isLocal: false,
      siteKey: response,
    }, 'POST');
    if (resp.ok) {
      return resp;
    }

    return null;
  } catch (error) {
    console.error('[USER_LOCATIONS]: Something went wrong with captcha', error);
    return null;
  }
}

export default {
  signUp,
  setPassword,
  passwordChange,
  login,
  logout,
  getUserLocations,
  getUserUnits,
  getUserUnitsFullInfo,
  getUserProfileSettings,
  updateUserProfileSettings,
  passwordRecover,
  getFaq,
  sendQuestion,
  captchaVerify,
};
