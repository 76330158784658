<template>
  <info-block
    class="stub-convertation"
    :withLabel="false"
  >
    <template #BlockTitle>
      {{ $t('blockTitle') }}
    </template>
    <template #BlockText>
      <ul>
        <li>{{ $t('blockText[0]') }}</li>
        <li>{{ $t('blockText[1]') }}</li>
        <li>{{ $t('blockText[2]') }}</li>
        <li>{{ $t('blockText[3]') }}</li>
      </ul>
    </template>
    <template #BlockImage>
      <div class="stub-convertation__img-wrapper">
        <img src="../../assets/convertationStubImg.png" alt="block image">
      </div>
    </template>
    <template #BlockButton>
      <secondary-button
        blue
        :label="$t('buttonText')"
        @click="clickHandler"
      >
        <template #iconBefore>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.36188 12.5264L4.10308 11.897L3.47368 10.6382C3.45061 10.5995 3.41786
              10.5674 3.37866 10.5451C3.33946 10.5228 3.29515 10.5111 3.25006
              10.5111C3.20497 10.5111 3.16065 10.5228 3.12145 10.5451C3.08225
              10.5674 3.04951 10.5995 3.02643 10.6382L2.39703 11.897L1.13823
              12.5264C1.0967 12.5471 1.06176 12.5791 1.03735 12.6186C1.01293
              12.6581 1 12.7036 1 12.75C1 12.7964 1.01293 12.842 1.03735 12.8815C1.06176
              12.921 1.0967 12.9529 1.13823 12.9736L2.39703 13.603L3.02643
              14.8618C3.04719 14.9034 3.07911 14.9383 3.11861 14.9627C3.15811
              14.9871 3.20362 15.0001 3.25006 15.0001C3.29649 15.0001 3.34201
              14.9871 3.38151 14.9627C3.42101 14.9383 3.45292 14.9034 3.47368
              14.8618L4.10308 13.603L5.36188 12.9736C5.40342 12.9529 5.43835
              12.921 5.46277 12.8815C5.48718 12.842 5.50012 12.7964 5.50012
              12.75C5.50012 12.7036 5.48718 12.6581 5.46277 12.6186C5.43835
              12.5791 5.40342 12.5471 5.36188 12.5264Z"
              fill="white"
            />
            <path
              d="M14.8619 10.5264L12.9363 9.56374L11.9737 7.63819C11.9506
              7.59945 11.9179 7.56737 11.8787 7.5451C11.8395 7.52282 11.7951
              7.51111 11.7501 7.51111C11.705 7.51111 11.6607 7.52282 11.6215
              7.5451C11.5823 7.56737 11.5495 7.59945 11.5264 7.63819L10.5638
              9.56374L8.63823 10.5264C8.5967 10.5471 8.56176 10.5791 8.53735
              10.6186C8.51293 10.6581 8.5 10.7036 8.5 10.75C8.5 10.7964 8.51293
              10.842 8.53735 10.8815C8.56176 10.921 8.5967 10.9529 8.63823
              10.9736L10.5638 11.9363L11.5264 13.8618C11.5472 13.9034 11.5791
              13.9383 11.6186 13.9627C11.6581 13.9871 11.7036 14.0001 11.7501
              14.0001C11.7965 14.0001 11.842 13.9871 11.8815 13.9627C11.921
              13.9383 11.9529 13.9034 11.9737 13.8618L12.9363 11.9363L14.8619
              10.9736C14.9034 10.9529 14.9384 10.921 14.9628 10.8815C14.9872
              10.842 15.0001 10.7964 15.0001 10.75C15.0001 10.7036 14.9872
              10.6581 14.9628 10.6186C14.9384 10.5791 14.9034 10.5471 14.8619
              10.5264Z"
              fill="white"
            />
            <path
              d="M10.3619 5.52639L7.43633 4.06374L5.97368 1.13819C5.95061
              1.09945 5.91786 1.06737 5.87866 1.0451C5.83946 1.02282 5.79515
              1.01111 5.75006 1.01111C5.70497 1.01111 5.66065 1.02282 5.62145
              1.0451C5.58225 1.06737 5.54951 1.09945 5.52643 1.13819L4.06378
              4.06374L1.13823 5.52639C1.0967 5.54715 1.06176 5.57906 1.03735
              5.61856C1.01293 5.65806 1 5.70358 1 5.75001C1 5.79645 1.01293
              5.84196 1.03735 5.88146C1.06176 5.92096 1.0967 5.95288 1.13823
              5.97364L4.06378 7.43629L5.52643 10.3618C5.54719 10.4034 5.57911
              10.4383 5.61861 10.4627C5.65811 10.4871 5.70362 10.5001 5.75006
              10.5001C5.79649 10.5001 5.84201 10.4871 5.88151 10.4627C5.92101
              10.4383 5.95292 10.4034 5.97368 10.3618L7.43633 7.43629L10.3619
              5.97364C10.4034 5.95288 10.4384 5.92096 10.4628 5.88146C10.4872
              5.84196 10.5001 5.79645 10.5001 5.75001C10.5001 5.70358 10.4872
              5.65806 10.4628 5.61856C10.4384 5.57906 10.4034 5.54715 10.3619
              5.52639Z"
              fill="white"
            />
          </svg>
        </template>
      </secondary-button>
    </template>
  </info-block>
</template>

<script>
import InfoBlock from '@/components/InfoBlock/InfoBlock.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';
import { useI18n } from 'vue-i18n';
import transcriptions from './transcriptions';

export default {
  name: 'StubConvertation',
  components: {
    InfoBlock,
    SecondaryButton,
  },
  emits: ['send-request'],
  setup(props, { emit }) {
    const { mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', transcriptions.en);
    mergeLocaleMessage('ru', transcriptions.ru);

    const clickHandler = () => {
      emit('send-request');
    };

    return {
      clickHandler,
    };
  },
};
</script>

<style lang="scss">
.stub-convertation {
  flex-direction: row-reverse;

  &__img {
    &-wrapper {
      width: 102px;
      height: 102px;
      margin: 28px 32px;
    }
  }

  .secondary-button {
    margin-top: 20px;
    padding: 12px 16px;
  }

  .info-block {
    &__column {
      flex-shrink: 1;
      padding: 28px 126px 28px 0;
    }

    &__title {
      font-size: $font-size-title-mid-small;
      line-height: 26px;
      font-weight: $font-weight-medium;
      color: $main-black;
      letter-spacing: -0.3px;
    }

    &__text {
      ul {
        margin: 0;
        padding-left: 20px;
        font-size: $font-size-regular;
        font-weight: $font-weight-regular;
        line-height: 18px;
        color: $grey800;
        letter-spacing: -0.3px;
      }

      li {
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .stub-convertation {
    margin-top: 35px;

    .info-block {
      &__column {
        flex-shrink: 1;
        padding: 28px 52px 28px 0;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .stub-convertation {
    margin-top: 0;
    width: calc(100% + 40px);
    margin-left: -20px;

    &__img-wrapper {
      display: none;
    }

    .secondary-button {
      margin-top: 16px;
    }

    .info-block {
      &__column {
        padding: 24px 32px;
      }

      &__title {
        font-size: $font-size-medium;
        line-height: 22px;
      }

      &__text {
        ul {
          font-size: $font-size-small;
          line-height: 16px;
        }

        li {
          margin-top: 6px;
        }
      }
    }
  }
}
</style>
