<template>
  <div
    class="mobile-menu-dropdown"
    :class="{
      'mobile-menu-dropdown--open': isOpen,
    }"
  >
    <ul
      class="mobile-menu-dropdown__list"
    >
      <li
        v-for="item in itemList"
        :key="`${item.id}_mobile-menu_${getRandomKey}`"
        class="mobile-menu-dropdown__list-element"
      >
        <router-link
          v-if="item.path"
          class="mobile-menu-dropdown__link"
          :to="item.path"
        >
          {{ item.label }}
        </router-link>
        <button
          v-else
          class="mobile-menu-dropdown__link"
          :class="{
            'mobile-menu-dropdown__link--disable': item.soon,
          }"
          :disabled="item.soon"
          @click="clickHandler(item.id)"
        >
          <span
            class="mobile-menu-dropdown__link-demo"
            v-if="item.soon"
          >
            скоро
          </span>
          {{ item.label }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'MobileMenuDropdown',
  emits: ['itemClick'],
  props: {
    itemList: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const getRandomKey = computed(() => Math.ceil(Math.random() * 1000000));
    const clickHandler = (id) => {
      emit('itemClick', id);
    };

    return {
      getRandomKey,
      clickHandler,
    };
  },
};
</script>

<style scoped lang="scss">
.mobile-menu-dropdown {
  height: 0;
  width: 100%;
  overflow: hidden;
  background-color: $grey200;
  transition: top .5s ease-out;

  &--open {
    height: initial;
    position: relative;
    z-index: 2;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    margin: 0;
    list-style: none;

    &-element {
      display: flex;
      height: 32px;
      min-width: 100%;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $main-black;
    font-size: $font-size-regular;
    font-weight: $font-weight-medium;
    text-decoration: none;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &.router-link-active {
      color: $blue500;
      pointer-events: none;
    }

    &-demo {
      display: inline-block;
      padding: 0 4px;
      margin-right: 4px;
      height: 11px;
      line-height: 11px;
      text-transform: uppercase;
      color: #fff;
      font-size: 7px;
      background: $blue500;
      border-radius: 6px;
    }

    &--disable {
      color: $grey700;

      .mobile-menu-dropdown__link-demo {
        background: $grey400;
      }
    }
  }
}

</style>
