<template>
  <div
    class="burger-menu"
    :class="{
      'burger-menu--open': isOpen,
    }"
  >
    <button
      class="burger-menu__button"
      @click="toggleMenu"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  name: 'BurgerMenu',
  props: {
    isClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['burgerToggle'],
  setup(props, { emit }) {
    const isOpen = ref(false);

    const toggleMenu = () => {
      isOpen.value = !isOpen.value;
      emit('burgerToggle', isOpen.value);
    };

    watch(() => props.isClose, () => {
      if (isOpen.value) {
        isOpen.value = false;
      }
    });

    return {
      isOpen,
      toggleMenu,
    };
  },
};
</script>

<style scoped lang="scss">
.burger-menu {
  display: none;
  margin-left: 16px;
  &__button {
    position: relative;
    display: block;
    padding: 4px;
    width: 12px;
    height: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $blue600;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2), &:nth-child(3) {
        top: 4px;
      }

      &:nth-child(4) {
        top: 8px;
      }
    }
  }

  &--open {
    .burger-menu__button {
      span {
        &:nth-child(1) {
          top: 4px;
          width: 0;
          left: 50%;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: 4px;
          width: 0;
          left: 50%;
        }
      }
    }
  }
}
@media screen and (max-width: $mobile-breakpoint) {
  .burger-menu {
    display: block;
  }
}
</style>
