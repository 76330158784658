export const BASE_DATA = [
  {
    id: 'phone',
    label: 'Телефон',
    required: true,
    inputType: 'tel',
  },
  {
    id: 'email',
    label: 'E-mail',
    required: true,
  },
  {
    id: 'company',
    label: 'Компания',
  },
  {
    id: 'position',
    label: 'Должность',
  },
];

export const BANK_DATA = [
  {
    id: 'receiver',
    label: 'Получатель',
  },
  {
    id: 'paymentAccount',
    label: 'Расчетный счет',
  },
  {
    id: 'correspondentAccount',
    label: 'Корр. счет',
  },
  {
    id: 'inn',
    label: 'ИНН',
  },
  {
    id: 'kpp',
    label: 'КПП',
  },
  {
    id: 'bik',
    label: 'БИК',
  },
  {
    id: 'bankName',
    label: 'Наименование банка',
  },
];

export const PATHS_UNRELATED_TO_LOCATIONS = ['/faq', '/news', '/promo', '/profile'];
