<template>
  <dashboard-layout
    class="booking-periphery-page"
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #outer-content>
      <ui-tabs
        :list="userLocations"
        :preselected-tab-id="currentUserLocation ? currentUserLocation.id : null"
        @tab-change="locationChangeHandler"
      />
    </template>
    <template #side-content>
      <main-side-menu
        :item-list="menuItems"
        active-item-id="booking-periphery"
      />
      <user-actives
        :units-list="userUnits"
      />
    </template>
    <template #main-content>
      <!-- Временно скрываем -->
      <summary-table
        v-if="false"
        class="booking-periphery-page__summary-table"
        :class="{
          'loading-stub': isLoadingStatistics,
        }"
      >
        <div class="booking-periphery-page__summary-table-title">
          <info-header
            class="summary-table__title"
            text="Cводные данные по комплексу"
          />
        </div>
        <!-- Временно скрываем... -->
        <div
          v-if="false"
          class="summary-table__row summary-table__row--bordered"
        >
          <div class="summary-table__block">
            <summary-info
              header="Средняя цена номера"
              firstTitle="За дни этого месяца"
              :firstInfo="income.thisMonth"
              secondTitle="За прошлый месяц"
              :secondInfo="income.lastMonth"
              :popupData="popupData.adr"
            />
          </div>
          <div class="summary-table__block">
            <summary-info
              header="Доход за номер"
              firstTitle="За дни этого месяца"
              :firstInfo="averageRoomPrice.thisMonth"
              secondTitle="За прошлый месяц"
              :secondInfo="averageRoomPrice.lastMonth"
              :popupData="popupData.revpar"
            />
          </div>
        </div>
        <!-- ...временно скрываем -->
        <div class="summary-table__row summary-table__row--triple">
          <div class="summary-table__block">
            <summary-info
              header="Загруженность на сегодня"
              firstTitle="Занято номеров"
              :firstInfo="loadToday.rooms"
            />
          </div>
          <div class="summary-table__block">
            <occupation-info
              header="Гости сегодня"
              :status-list="guestsToday"
              :max-rooms-amount="countOfUnits"
            />
          </div>
          <!-- Временно скрываем... -->
          <div
            v-if="false"
            class="summary-table__block"
          >
            <info-chart-donut
              header="Состояние номеров"
              :legend="unitsStatus"
              :legend-max-amount="countOfUnits"
            />
          </div>
          <!-- ...временно скрываем -->
        </div>
      </summary-table>
      <title-main
        class="booking-periphery-page__title"
        :text="t('bookingPage.outbuildingsBookings')"
        icon
      >
        <template #icon>
          <img src="../assets/booking.svg" alt="booking-icon">
        </template>
      </title-main>
      <div class="booking-periphery-page__info">
        Бронирования периферии в шахматке отображаются по часам, 
        то есть один блок — это один час.
      </div>
      <div class="booking-periphery-page__table">
        <div class="booking-periphery-page__table-controls">
          <div class="booking-periphery-page__table-filters">
            <div class="booking-periphery-page__table-filters-block">
              <span>
                {{ t('bookingPage.startDate') }}
              </span>
              <filter-date
                :disabled="isSimaginoLocationNow"
                today-start
                :past-dates-options="pastDatesOptionsList"
                :future-dates-options="futureDatesOptionsList"
                @filter-date-select="startDateSelectHandler"
                calendarIcon
              />
            </div>
            <filter-dropdown
              v-if="!isSimaginoLocationNow"
              :filter-name="t('source')"
              :filter-values="bookingSources"
              @filter-select="bookingSourcesSelectHandler"
            />
          </div>
        </div>
        <stub-block-no-data
          v-if="isSimaginoLocationNow"
          style="margin-top: 45px;"
          :title="t('bookingPage.stubTitle')"
          :sub-title="t('bookingPage.stubSubTitle')"
        >
          <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M2 20V3H0V21C0 21.2652 0.105357 21.5196 0.292893 21.7071C0.48043 21.8946
                0.734784 22 1 22H24V20H2Z" fill="#89909E"/>
              <path d="M9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11
                8 11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4
                12V19H9V12Z" fill="#89909E"/>
              <path d="M16 8C16 7.73478 15.8946 7.48043 15.7071 7.29289C15.5196 7.10536 15.2652
                7 15 7H12C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11
                8V19H16V8Z" fill="#89909E"/>
              <path d="M23 4C23 3.73478 22.8946 3.48043 22.7071 3.29289C22.5196 3.10536 22.2652
                3 22 3H19C18.7348 3 18.4804 3.10536 18.2929 3.29289C18.1054 3.48043 18 3.73478 18
                4V19H23V4Z" fill="#89909E"/>
            </svg>
          </template>
        </stub-block-no-data>
        <booking-table
          v-else
          style="margin-top: 25px;"
          is-hourly
          :currentDate="currentDate"
          :dateStart="dateStart"
          :dateEnd="dateEnd"
          :grouped-units="bookingPeripheryData"
          :timeline="timeline"
          :filters="bookingFilters"
          :is-loading="isLoading"
          :is-first-loading-finished="isFirstLoadingFinished"
          @scrolledToEnd="scrolledToEndHandler"
          @scrolledToStart="scrolledToStartHandler"
        />
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { updateMoneySum } from '@/helpers/common';
import { pastDatesOptions, futureDatesOptions } from '@/helpers/datesAndTime';
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import UiTabs from '@/ui-kit/UiTabs/UiTabs.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import FilterDropdown from '@/components/FilterDropdown/FilterDropdown.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
import BookingTable from '@/components/BookingTable/BookingTable.vue';
import SummaryTable from '@/components/SummaryTable/SummaryTable.vue';
import SummaryInfo from '@/components/SummaryInfo/SummaryInfo.vue';
import OccupationInfo from '@/components/OccupationInfo/OccupationInfo.vue';
import InfoChartDonut from '@/components/InfoChartDonut/InfoChartDonut.vue';
import StubBlockNoData from '@/components/StubBlockNoData/StubBlockNoData.vue';
import UserActives from '@/components/UserActives/UserActives.vue';
import FilterDate from '@/components/FilterDate/FilterDate.vue';
import InfoHeader from '@/ui-kit/InfoHeader/InfoHeader.vue';
import { POPUP_DATA, BOOKING_SOURCES } from '@/constants/booking';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import translates from './translates';

export default {
  name: 'BookingPage',
  components: {
    StubBlockNoData,
    TitleMain,
    MainSideMenu,
    FilterDropdown,
    DashboardLayout,
    BookingTable,
    UiTabs,
    SummaryTable,
    SummaryInfo,
    OccupationInfo,
    InfoChartDonut,
    FilterDate,
    UserActives,
    InfoHeader,
  },
  mounted() {
    window.scrollTo(0, 0); // Прокрутка к началу страницы при монтировании компонента
  },
  setup() {
    const store = useStore();
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const bookingState = store.state.booking;
    const userState = store.state.user;
    const menuItems = computed(() => userState.sideMenuItems);
    const popupData = ref(POPUP_DATA);
    const bookingSources = ref(BOOKING_SOURCES.map(({ id, label }) => ({
      id,
      label: t(label),
    })));
    const selectedBookingSource = ref({});
    const userUnits = computed(() => userState.units);
    const currentDate = computed(() => bookingState.currentDate);
    const dateStart = computed(() => bookingState.dateStart);
    const dateEnd = computed(() => bookingState.dateEnd);
    const bookingPeripheryData = computed(() => bookingState.bookingPeripheryData);
    const bookingFilters = computed(() => bookingState.filters);
    const timeline = computed(() => bookingState.timeline.groupedByMonth);
    const isLoading = computed(() => bookingState.isLoading);
    const isFirstLoadingFinished = computed(() => bookingState.isFirstLoadingFinished);
    const isLoadingStatistics = computed(() => bookingState.isLoadingStatistics);
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name })));
    const isSimaginoLocationNow = computed(() => userState.currentLocation
      && userState.currentLocation.id === 2); // временный костыль
    const currentUserLocation = computed(() => userState.currentLocation);
    const countOfUnits = computed(() => bookingState.countOfUnits || '0');
    const loadToday = computed(() => ({
      rooms: bookingState.loadToday.countOfUnits
        ? `${bookingState.loadToday.countOfUnits}`
        : '0',
      percentOfUnits: bookingState.loadToday.percentOfUnits
        ? `${bookingState.loadToday.percentOfUnits}\u202F%`
        : '0\u202F%',
    }));
    const income = computed(() => {
      const updatedIncome = bookingState.income;
      updatedIncome.thisMonth = updatedIncome.thisMonth
        ? `${updateMoneySum(updatedIncome.thisMonth)}\u202F₽`
        : '0\u202F₽';
      updatedIncome.lastMonth = updatedIncome.lastMonth
        ? `${updateMoneySum(updatedIncome.lastMonth)}\u202F₽`
        : '0\u202F₽';

      return updatedIncome;
    });
    const guestsToday = computed(() => bookingState.guestsToday || '0');
    const unitsStatus = computed(() => bookingState.unitsStatus || '0');
    const averageRoomPrice = computed(() => {
      const updatedAverageRoomPrice = bookingState.averageRoomPrice;
      updatedAverageRoomPrice.thisMonth = updatedAverageRoomPrice.thisMonth
        ? `${updateMoneySum(updatedAverageRoomPrice.thisMonth)}\u202F₽`
        : '0\u202F₽';
      updatedAverageRoomPrice.lastMonth = updatedAverageRoomPrice.lastMonth
        ? `${updateMoneySum(updatedAverageRoomPrice.lastMonth)}\u202F₽`
        : '0\u202F₽';

      return updatedAverageRoomPrice;
    });
    const showDropdownPopup = ref(false);
    const pastDatesOptionsList = ref(pastDatesOptions.weeks().map(({ id, label }) => ({
      id,
      label: t(label),
    })));
    const futureDatesOptionsList = ref(futureDatesOptions.months().map(({ id, label }) => ({
      id,
      label: t(label),
    })));
    const infoHeaderPopupData = {
      header: '',
      text: t('bookingPage.infoHeaderPopupData'),
    };

    const initBookingData = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/initSideMenuItems');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }
      store.dispatch('booking/getBookingPeripheryStatistics');
      await store.dispatch('booking/initPeripheryBookingsTable');
    };
    const scrolledToEndHandler = async () => {
      await store.dispatch('booking/updatePeripheryBooking', { isNext: true });
    };
    const scrolledToStartHandler = async () => {
      await store.dispatch('booking/updatePeripheryBooking', { isNext: false });
    };
    const startDateSelectHandler = async (date) => {
      await store.dispatch('booking/updateDatesStarEnd', {
        date,
        isHourly: true,
      });
      await store.dispatch('booking/updatePeripheryBooking');
    };
    const bookingSourcesSelectHandler = async (source) => {
      if (!source) {
        return;
      }
      selectedBookingSource.value = selectedBookingSource.value.id === source.id ? {} : source;
      await store.dispatch('booking/setFilter', {
        name: 'source',
        value: selectedBookingSource.value.id,
      });
    };

    const locationChangeHandler = (id) => {
      store.dispatch('user/setCurrentLocation', id);
      initBookingData();
    };

    initBookingData();
    return {
      t,
      popupData,
      bookingSources,
      selectedBookingSource,
      menuItems,
      userUnits,
      currentDate,
      bookingPeripheryData,
      bookingFilters,
      dateStart,
      dateEnd,
      timeline,
      isLoading,
      userLocations,
      currentUserLocation,
      countOfUnits,
      loadToday,
      income,
      guestsToday,
      unitsStatus,
      averageRoomPrice,
      showDropdownPopup,
      infoHeaderPopupData,
      scrolledToEndHandler,
      scrolledToStartHandler,
      bookingSourcesSelectHandler,
      startDateSelectHandler,
      locationChangeHandler,
      isFirstLoadingFinished,
      isLoadingStatistics,
      pastDatesOptionsList,
      futureDatesOptionsList,
      isSimaginoLocationNow,
    };
  },
};
</script>

<style lang="scss">
.booking-periphery-page {
  &__info {
    margin-top: 8px;
    max-width: 740px;
    line-height: 18px;
    font-size: $font-size-regular;
    color: $grey800;
  }
  
  &__table {
    margin-top: 32px;

    &-controls {
      display: flex;
      justify-content: flex-end;
    }

    &-filters {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &-block {
        display: flex;
        align-items: center;

        &>span {
          font-size: $font-size-regular;
          font-weight: $font-weight-medium;
          color: $grey900;
        }

        .filter-date {
          margin-left: 15px;
        }
      }
    }
  }

  &__stub {
    margin-bottom: 60px;
  }
  // Временно убираем
  // &__title {
  //   margin-top: 60px;
  // }

  &__summary-table {
    box-sizing: border-box;
    background: $grey100;
    padding: 20px 32px 20px 20px;
    border: 1px solid $grey200;

    .summary-table__title {
      .info-header {
        font-size: $font-size-title-small;
        font-weight: $font-weight-half-bold;
      };
    }
  }

  .summary-table {
    &__row {
      &:nth-child(2) {
        border-top: 1px solid $grey200;
        margin-top: 24px;
      }
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .booking-periphery-page {
    &__summary-table {
      flex-direction: column;

      &-title {
        padding-bottom: 33px;
        border-bottom: 1px solid $grey200;
      }

      .summary-table {
        &__row {
          width: 100%;
          border-left: none;

          &:not(:first-child) {
            border-top: none;

            .summary-table__block {
              padding: 15px 12px 15px 0;
            }
          }

          &:nth-child(2) {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .booking-periphery-page {
    &__summary-table {
      padding: 0;
      background-color: transparent;
      border: none;

      &-title {
        .info-header {
          &__text {
            font-size: $font-size-medium;
          }
        }
      }
    }

    &__title {
      padding-bottom: 24px;
    }

    &__table {
      &-filters {
        flex-direction: column;
        align-items: flex-start;

        .filter-dropdown {
          margin-top: 12px;
        }
      }
    }
  }
}
</style>
