<template>
  <div class="role-toggler__wrapper">
    <div class="role-toggler">
      <button
        class="role-toggler__button"
        :class="{
        'role-toggler__button--active': preselectedValue === 'INVESTOR',
      }"
        @click="roleChange('INVESTOR')"
      >
        {{ $t('investor') }}
      </button>
      <button
        class="role-toggler__button"
        :class="{
        'role-toggler__button--active': preselectedValue === 'LENDER',
      }"
        @click="roleChange('LENDER')"
      >
        {{ $t('lender') }}
      </button>
    </div>
    <LocationsListMobile
      class="role-toggler--mobile"
      :current-location="{ name: ROLES[preselectedValue], value: preselectedValue }"
      @toggle-location-menu="toggleMobileRolesMenu"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import LocationsListMobile from '@/ui-kit/LocationsListMobile/LocationsListMobile.vue';
import { useI18n } from 'vue-i18n';
import transcriptions from './transcriptions';

export default {
  name: 'RoleToggler',
  components: {
    LocationsListMobile,
  },
  props: {
    preselectedValue: {
      type: String,
      default: '',
    },
  },
  emits: ['roleChange', 'toggleMobileRolesMenu'],
  setup(props, { emit }) {
    const { mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', transcriptions.en);
    mergeLocaleMessage('ru', transcriptions.ru);
    const ROLES = { INVESTOR: 'Инвестиции', LENDER: 'Займы' };
    const selectedValue = computed(() => props.preselectedValue && props.preselectedValue.length
      ? props.preselectedValue
      : null);
    const roleChange = (role) => {
      emit('roleChange', role);
      selectedValue.value = role;
    };

    const toggleMobileRolesMenu = (isOpen) => {
      emit('toggleMobileRolesMenu', isOpen);
    };
    return {
      roleChange,
      toggleMobileRolesMenu,
      selectedValue,
      ROLES,
    };
  },
};
</script>

<style scoped lang="scss">
.role-toggler {
  display: flex;

  &__wrapper {
    margin-bottom: -21px;
    margin-right: auto;
    margin-left: 40px;
  }

  &__button {
    padding-bottom: 20px;
    margin-left: 24px;
    color: $main-black;
    font-size: $font-size-regular;
    font-weight: $font-weight-medium;
    border: none;
    border-bottom: 2px solid transparent;
    background: transparent;
    outline: none;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &--active {
      color: $blue500;
      font-weight: $font-weight-bold;
      border-bottom-color: $blue500;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .role-toggler {
    display: none;

    &__wrapper {
      margin-bottom: 0;
      margin-right: 0;
      margin-left: 0;
    }

    &--mobile {
      margin-right: 4px;
    }
  }
}
</style>
