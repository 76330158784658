<template>
  <dashboard-layout
    class="control-page"
    overflow-clip
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #outer-content>
      <ui-tabs
        :list="userLocations"
        :preselected-tab-id="currentUserLocation"
      />
    </template>
    <template #side-content>
      <main-side-menu
        :item-list="menuItems"
        active-item-id="control"
      />
      <user-actives
        :units-list="userUnits"
      />
    </template>
    <template #main-content>
      <div class="inspection-page">
        <router-link
          class="inspection-page__back-btn-wrapper"
          to="/dashboard/control"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
          >
            <path
              d="M3 6.99998C3 6.80284 3.07203 6.60572 3.21578 6.45542L7.74168 1.72566C8.02959
              1.42478 8.49637 1.42478 8.78416 1.72566C9.07195 2.02641 9.07195 2.51413 8.78416
              2.81503L4.77939 6.99998L8.78402 11.185C9.07181 11.4858 9.07181 11.9735 8.78402
              12.2742C8.49623 12.5753 8.02945 12.5753 7.74154 12.2742L3.21564 7.54455C3.07187
              7.39417 3 7.19705 3 6.99998Z"
              fill="#219C67"
            />
          </svg>
          <div class="inspection-page__back-btn">
            Назад ко всем проверкам
          </div>
        </router-link>
        <div class="inspection-page__title-block">
          <title-main
            class="inspection-page__title"
            :text="inspectionListTitle"
          />
        </div>
        <div class="inspection-page__content">
          <summary-table>
            <div class="summary-table__row">
              <div
                v-if="inspectionDate"
                class="summary-table__block"
              >
                <summary-info
                  header="Дата проверки"
                  :firstInfo="inspectionDate"
                />
              </div>
              <div
                v-if="inspectionResult"
                class="summary-table__block"
              >
                <summary-info
                  header="Результат проверки"
                  :firstInfo="inspectionResult"
                />
              </div>
              <div
                v-if="inspectionFails"
                class="summary-table__block"
              >
                <summary-info
                  header="Выявлено нарушений"
                  :firstInfo="inspectionFails"
                />
              </div>
            </div>
          </summary-table>
          <report-list
            v-if="inspectionsArray && inspectionsArray.length"
            :testsArray="inspectionsArray"
          />
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
import UserActives from '@/components/UserActives/UserActives.vue';
import SummaryTable from '@/components/SummaryTable/SummaryTable.vue';
import SummaryInfo from '@/components/SummaryInfo/SummaryInfo.vue';
import ReportList from '@/components/ReportList/ReportList.vue';
import UiTabs from '@/ui-kit/UiTabs/UiTabs.vue';

import { prepareShortMonthDate } from '@/helpers/datesAndTime';

export default {
  name: 'inspectionPage',
  components: {
    TitleMain,
    MainSideMenu,
    DashboardLayout,
    UserActives,
    SummaryTable,
    SummaryInfo,
    ReportList,
    UiTabs,
  },
  mounted() {
    window.scrollTo(0, 0); // Прокрутка к началу страницы при монтировании компонента
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const userState = store.state.user;
    const inspectionState = store.state.inspection;
    const menuItems = computed(() => userState.sideMenuItems);
    const userUnits = computed(() => userState.units);
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name })));
    const currentUserLocation = computed(() => userState.currentLocation);
    const inspectionId = router.currentRoute.value.params.id;
    const inspectionDate = computed(() => inspectionState.data.inspectionDate
      ? prepareShortMonthDate(inspectionState.data.inspectionDate, {})
      : null);
    const inspectionResult = computed(() => inspectionState.data.inspectionFactRate
      ? `${inspectionState.data.inspectionFactRate}/${inspectionState.data.inspectionMaxRate}\u202F(${inspectionState.data.successPercentage}%)`
      : '');
    const inspectionFails = computed(() => inspectionState.data.inspectionFails);
    const inspectionListTitle = computed(() => inspectionState.data.inspectionListName);
    const inspectionsArray = computed(() => inspectionState.inspectionsArray);

    const initInspectionData = async () => {
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/initSideMenuItems');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }
      await store.dispatch('inspection/getInspectionDataById', inspectionId);
    };

    const locationChangeHandler = (id) => {
      store.dispatch('user/setCurrentLocation', id);
      initInspectionData();
    };

    initInspectionData();

    return {
      menuItems,
      userUnits,
      userLocations,
      currentUserLocation,
      inspectionDate,
      inspectionResult,
      inspectionFails,
      inspectionListTitle,
      inspectionsArray,
      locationChangeHandler,
    };
  },
};
</script>

<style lang="scss">
.inspection-page {

  &__back-btn {
    font-size: $font-size-regular;
    line-height: 17px;
    font-weight: $font-weight-medium;
    color: $blue500;
    margin-left: 4px;

    &-wrapper {
      display: flex;
      align-items: center;
      text-decoration: none;
    }
  }

  &__title {
    &-block {
      margin-top: 60px;
    }
  }

  &__content {
    .report-list {
      margin-top: 60px;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .inspection-page {
    &__content {
      .summary-table {
        &__row {
          flex-direction: row;
          width: 100%;
        }

        &__block {
          &:nth-child(2) {
            border-left: 1px solid $grey200;
            padding-left: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .inspection-page {
    &__content {
      .summary-table {
        &__row {
          flex-direction: column;
        }

        &__block {
          &:nth-child(2) {
            border-left: none;
            padding-left: 0;
          }
        }
      }
    }
  }
}
</style>
